import React from "react";
import { withRouter } from "react-router";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//// functions need to use react with our component
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
// import reducer from "./store/reducers";
// import withReducer from "store/withReducer";
// import * as Actions from "./store/actions";
import * as BaseActions from "store/actions";
import DummyImage from "../../../../@assets/dummyImage.png"
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    timer: {
        backgroundColor: "#09161C",
        borderRadius: "6px",
        // height: "40px",
        // width:'100%',
        margin: "0 20px",
        padding: "10px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "& > .image": {
            height: "46px",
            width: "46px",
            borderRadius: "4px",
            // border: "1px solid white",
            overflow: "hidden",
        },
        "& > .timer": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "17px",
            marginTop: "5px"
        },
        "& > .button": {
            backgroundColor: "#4CDE5F",
            borderRadius: "6px",
            height: "28px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
            maxWidth: "65px",
            cursor: "pointer",
        },
    },
}));

function QuartrTimer({ history, nextAppointment }) {

    const attendee = nextAppointment?.attendee
    const host = nextAppointment?.host
    const loggedInUser = JSON.parse(localStorage.getItem('quarterlyUser')).data._id
    const name = loggedInUser !== attendee?._id ? (attendee?.enabledUsername ? attendee?.username : attendee?.full_name) : (host?.enabledUsername ? host?.username : host?.full_name)
    const img = loggedInUser !== attendee?._id ? attendee?.profile_media_urls[0] : host?.profile_media_urls[0]

    const today = moment().format("ddd, MM/DD")
    const date = moment(nextAppointment.call_at).format("ddd, MM/DD")
    const timeNDate = date === today ? "Today" : moment(nextAppointment.call_at).format("ddd, MM/DD")

    const classes = useStyles();
    return (
        <>
            <Box className={classes.timer}>
                {/* img */}
                <Box className="image">
                    <img
                        style={{ height: "100%", width: "100%" }}
                        src={img === "https://narsunprojects.com:3001/uploads/default_pic.png" || img === "" ? DummyImage : img}
                        // src={DummyImage}
                        alt="https://narsunprojects.com:3001/uploads/default_pic.png"
                    />
                </Box>
                <Box style={{ flexGrow: 1, paddingLeft: "10px" }}>
                    <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
                        Next Quartr: {timeNDate} @ {moment(nextAppointment.call_at).format("hh:mmA")}
                    </Typography>
                    <Typography variant="body2">
                        {name}
                    </Typography>
                </Box>
                {/* <Box className="button">
                    <Typography variant="subtitle1" style={{ fontSize: "16px" }}>
                    Join
                    </Typography>
                </Box> */}
            </Box>
        </>
    );
}

export default withRouter(React.memo(QuartrTimer));
