import React from "react";
import pics from "@assets";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { logout } from "@utils";
import * as BaseActions from "store/actions";
import { makeStyles } from "@material-ui/styles";
import Reviews from "components/reviews";
import { useHistory } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";
import { isMobile } from "react-device-detect";
import DummyImage from "../../@assets/dummyImage.png"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
  },
  modalContainer: {
    outline: "none",
    position: "fixed",
    bottom: 0,
    padding: "10px",
    borderRadius: 0,
    color: "#FFFFFF",
    backgroundColor: "#111111",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: "0",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "33px",
      width: "370px",
    },
  },
  modalLogoutContainer: {
    outline: "none",
    position: "fixed",
    bottom: 0,
    // padding: "10px",
    borderRadius: 0,
    color: "#FFFFFF",
    backgroundColor: "#111111",
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // width: "100%",
    // padding: "0% 5%",
    width: "100%",
    justifyContent: 'center',
    marginBottom: "0",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "33px",
      width: "370px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "415px",
    },
  },
  modalActionContainer: {
    marginTop: "20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textLeft: {
    justifyContent: 'left',
    textAlign: 'left',
    alignItems: 'left'
  }
}));
const DrawerMenu = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector(({ BaseReducer }) => BaseReducer.UserReducer);
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: true }));
    dispatch(BaseActions.getUserData());
    return () => {
      dispatch(BaseActions.updateLayout({ bottomNav: true }));
    };
  }, [dispatch]);
  const handleHide = () => {
    dispatch(BaseActions.hideMessage());
  };
  const handleReview = (id, reviews) => {
    dispatch(
      BaseActions.showMessage(<Reviews count={reviews} handleClose={handleClose} userId={id} />)
    );
  }
  const handleClose = () => {
    dispatch(BaseActions.hideMessage());
  };
  const handleGotoPage = (page) => {
    props.history.push(`/${page}`)
    handleClose()
  }
  return (
    <div
      style={{
        // marginBottom: "56px",
        background: "#080A0A",
        overflowY: "scroll",
        paddingTop: "20px",
        // marginBottom: 20,
        marginBottom: 50,
      }}
    >
      <Box margin="0px 30px" paddingTop="5px">
        {/* profile view top */}
        <ProfileSection
          handleHide={handleHide}
          handleRating={handleReview}
          data={{
            fullName: userData.enabledUsername ? userData.username : userData.full_name,
            email: userData.email,
            rating: userData.rating,
            reviews: userData.reviews_count,
            _id: userData._id,
            user_code: userData.user_code,
            // profile: pics.dp,
            profile:
              userData.profile_media_urls && userData.profile_media_urls[0],
          }}
        />
      </Box>
      <Box
        margin="40px 0px"
        padding="10px 30px"
        style={{
          background: "#09161C",
          userSelect: "none",
          cursor: "pointer"
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleGotoPage.bind(this, 'balance')}
      >
        <Typography variant="body2" style={{ color: "#fff" }}>Balance</Typography>
        <Typography variant="body2" style={{ color: "#fff" }}>
          $
          {userData.available_amount
            ? Number(userData.available_amount).toFixed(2)
            : Number(0).toFixed(2)}
        </Typography>
      </Box>
      <Box margin="0px 30px" paddingBottom="40px">
        <ListTile title="Earnings" img={pics.wallet} handleAction={handleGotoPage.bind(this, 'earnings')} />
        <ListTile title="Invite" img={pics.location} handleAction={handleGotoPage.bind(this, 'invite')} />
        {/* <ListTile title="Invite" img={pics.location} /> */}
        <ListTile
          title="Payment"
          handleAction={handleGotoPage.bind(this, 'payment')}
          img={pics.whiteCard}
        />
        <ListTile handleAction={handleGotoPage.bind(this, 'tandc')} title="Legal" img={pics.justice} />
        <ListTile
          handleAction={() => {
            dispatch(BaseActions.updateLayout({ bottomNav: false }))
            props.history.push("/help")
          }}
          title="Help"
          img={pics.help}
        />
        <Box paddingBottom="20px">
          <ListTile title="Logout" img={pics.logout} last isLogout />
        </Box>
      </Box>
    </div>
  );
};
export default withRouter(DrawerMenu);
const ProfileSection = ({ data, handleHide, handleRating, props }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const history = useHistory();
  const handleNavigation = () => {
    history.push("/profile")
    dispatch(BaseActions.hideMessage());
  };
  const handleModalClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box display="flex" width="100%">
        {/* <Link to="/profile"> */}
        <img
          style={{ height: "80px", width: "80px", borderRadius: "6px", cursor: "pointer" }}
          src={`${data.profile === "https://narsunprojects.com:3001/uploads/default_pic.png" || data.profile === "" ? DummyImage : data.profile}`}
          alt={data.profile}
          onClick={handleNavigation}
        />
        {/* </Link> */}
        <Box
          display="flex"
          flexDirection="column"
          // justifyContent="space-between"
          marginLeft="15px"
        >
          {/* <Link to="/profile"> */}
          <Typography onClick={handleNavigation} variant="body2" style={{ textTransform: 'capitalize', cursor: "pointer" }}>{data.fullName}</Typography>
          {/* <Typography onClick={handleNavigation} variant="caption" style={{ cursor: "pointer" }}>{data.email}</Typography> */}
          <Typography onClick={handleNavigation} variant="caption" style={{ cursor: "pointer" }}>{data.user_code}</Typography>
          {/* </Link> */}
          <Box style={{ cursor: 'pointer' }} display="flex" alignItems="center" onClick={() => handleRating(data._id, data.reviews)}>
            <img
              style={{ height: "14px", width: "15px", marginRight: "6px" }}
              src={pics.rating}
              alt={pics.rating}
            />
            <Typography
              variant="subtitle1"
              style={{ color: "white", marginTop: "auto", fontSize: "16px" }}
            >
              {Number(data.rating).toFixed(1)} ({data.reviews} reviews)
            </Typography>
          </Box>
        </Box>
        <CloseIcon
          style={{ marginLeft: "auto", cursor: "pointer", color: '#CB3A3A' }}
          onClick={() => handleHide()}
        />
        <Modal
          open={open}
          onClose={handleModalClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box className={classes.modalContainer}>
            <Box>
              <Typography variant="caption">
                Are you sure you want to close?
              </Typography>
              <Box marginTop="auto" className={classes.modalActionContainer}>
                <Button
                  onClick={handleModalClose}
                  variant="outlined"
                  color="default"
                  style={{ width: "46%" }}
                >
                  {"Cancel"}
                </Button>
                <Button
                  onClick={() => handleHide()}
                  color="primary"
                  style={{ width: "46%" }}
                >
                  {"Yes"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};
const ListTile = ({ img, last, title, isLogout, handleAction }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const handleModalClose = () => {
    setOpen(false);
    dispatch(BaseActions.hideMessage());
  };
  const handleLogout = () => {
    dispatch(BaseActions.showLoading(true));
    logout()
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      marginTop="30px"
      marginBottom={last ? "20px" : "0"}
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (last) {
          // logout();
          // window.location.href = "/";
          // return;
          setOpen(true)
          dispatch(BaseActions.updateLayout({ bottomNav: false }));
        }
        handleAction && handleAction();
      }}
    >
      <img src={img} alt={img} style={{ height: "20px", width: "20px" }} />
      <Typography
        variant="caption"
        style={{ fontSize: "16px", marginLeft: "20px", color: isLogout && '#CB3A3A' }}
      >
        {title}
      </Typography>
      <Modal
        open={open}
        onClose={handleModalClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className={classes.modalLogoutContainer}>
          <Box padding={isAndroid ? "50px" : "40px"} width="100%"> {/**370 */}
            <Typography variant="body2">Logout</Typography>
            <Box marginTop="20px">
              <Typography variant="caption">
                Are you sure you want to logout?
              </Typography>
              <Box marginTop="auto" className={classes.modalActionContainer}>
                <Button
                  onClick={handleModalClose}
                  variant="outlined"
                  color="default"
                  style={{ width: "45%" }}
                >
                  {"No"}
                </Button>
                <Button
                  onClick={handleLogout}
                  color="primary"
                  style={{ width: "45%" }}
                >
                  {"Yes"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
ListTile.defaultProps = {
  img: "/img",
  title: "title",
  last: false,
};
