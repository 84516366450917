import iPhoneImage from '../../@assets/LandingPageAssets/iphone.png'
import AvailabilityImage from '../../@assets/LandingPageAssets/availability.png'
import PaymentImage from '../../@assets/LandingPageAssets/earnings.png'
import PickadayImage from '../../@assets/LandingPageAssets/pickaday.png'
import VideocallsImage from '../../@assets/LandingPageAssets/videocalls.png'
import { makeStyles } from '@material-ui/core/styles'
import checkbox from '../../@assets/LandingPageAssets/checkbox.svg'
import USA from '../../@assets/LandingPageAssets/USA.svg'
import Header from './Header';
import { Link, useHistory } from 'react-router-dom'
import Footer from './Footer'
import React, { useRef, useEffect } from 'react'
import * as BaseActions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Container from "@material-ui/core/Container";
import ReactPlayer from "react-player";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import './App.css'
import PlayButton from "@assets/videoPlayerIcons/play.png"
import PauseButton from "@assets/videoPlayerIcons/pause.png"
import ReplayButton from "@assets/videoPlayerIcons/replay.png"
import ThumbnailImage from "@assets/videoPlayerIcons/thumbnail.png"
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: "#080A0A"
    },
    bannerContainer: {
        backgroundColor: "#111111",
        paddingTop: '3%',
        width: "83%",
        margin: '0 auto',
        borderRadius: '10px',
    },
    Container: {
        backgroundColor: "#111111",
        padding: '5% 0%',
        width: "83%",
        margin: '0 auto',
        borderRadius: '10px'
    },
    bannerContainerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '5%',
        overflow: 'hidden',
        height: '590px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '2% 0',
            padding: '0% 10%',
            height: '760px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '800px',
        },
    },
    bannerHeading: {
        color: '#31A7DB',
        // fontFamily: 'Cerebri-Sans',
        fontSize: '50px',
        marginTop: '25%',
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            fontSize: '40px',
            textAlign: 'center',
            marginTop: '25%',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '30px',
            textAlign: 'center',
            marginTop: '0%',
        }
    },
    bannerHeading2: {
        color: '#808080',
        // fontFamily: 'Cerebri-Sans',
        fontWeight: '400',
        fontSize: '30px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            textAlign: 'center'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '22px',
            textAlign: 'center'
        },
    },
    bannerImage: {
        height: '700px',
        [theme.breakpoints.down('sm')]: {
            height: '500px',
            // marginLeft: '-100px'
        }
    },
    bannerContainerTextSize: {
        width: '41%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        background: '##080A0A',
        padding: '2% 2%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '2% 0'
        },
    },
    contentContainer2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        background: '##080A0A',
        padding: '2% 2%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'center',
            marginTop: '0%'
        },
    },
    contentContainerMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%'
        },
    },
    contentImage: {
        height: '739px',
        [theme.breakpoints.down('sm')]: {
            height: '500px',
        },
    },
    contentTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '35%',
        [theme.breakpoints.down('sm')]: {
            margin: '2% 0',
        },
    },
    contentTextContainerSize: {
        width: '32%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    contentTextHeading: {
        color: '#31A7DB',
        fontSize: '40px',
        // fontFamily: 'Cerebri-Sans',
        [theme.breakpoints.down('sm')]: {
            // fontSize: '30px',
            textAlign: 'center',
            fontSize: '30px',
        }
    },
    contentTextHeading2: {
        color: '#808080',
        fontSize: '30px',
        fontWeight: '400',
        // fontFamily: 'Cerebri-Sans',
        [theme.breakpoints.down('sm')]: {
            // fontSize: '25px',
            // wordBreak: 'break-all',
            textAlign: 'center',
            fontSize: '20px',
        }
    },
    bottomContainerTextContent: {
        width: '17%',
        marginTop: '5%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '2%',
        }
    },
    bottomContainerContent: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: '0% 0%',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            flexDirection: 'column'
        }
    },
    bottomContentGuidelines: {
        width: '41%',
        [theme.breakpoints.down('sm')]: {
            width: '91%',
            padding: '0% 5%'
        }
    },
    guidelines: {
        display: 'flex',
        flexDirection: 'row'
    },
    guidelinesText: {
        paddingLeft: '4%',
        color: '#808080',
        fontSize: '18px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
        }
    },
    justifyCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    justifyColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    countryName: {
        color: '#808080',
        textTransform: 'uppercase',
        fontSize: '30px',
        // fontFamily: 'Cerebri-Sans'
    },
    vote: {
        color: '#808080',
        fontSize: '30px',
        fontWeight: '400px',
        // fontFamily: 'Cerebri-Sans',
        textAlign: 'center'
    },
    voteButton: {
        background: 'transparent',
        border: '1px solid #fff',
        borderRadius: '6px',
        width: '272px',
        height: '53px',
        color: 'white',
        fontSize: '20px',
        cursor: 'pointer',
        '&:hover': {
            color: '#111111',
            background: '#808080'
        }
    },
    countryImage: {
        [theme.breakpoints.down('sm')]: {
            // width: '80%',
        }
    },
    mobileHeading: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: '10%'
        }
    },
    bannerHeadingMobile: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '30%'
        }
    },
    playerWrapper: {
        width: "100%",
        position: "relative",
    },
    controlsWrapper: {
        paddingTop: '10%',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // background: "rgba(0,0,0,0.6)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        zIndex: 1,
    },
    controlIcons: {
        color: "#777",
        fontSize: 50,
        transform: "scale(0.9)",
        "&:hover": {
            color: "#fff",
            transform: "scale(1)",
        },
    },
}));

function Home() {
    const dispatch = useDispatch();
    const [history, location] = [useHistory(), useLocation()]
    const classes = useStyles();
    // React.useEffect(() => {
    //     dispatch(BaseActions.updateLayout({ fullScreen: true }));
    //     if (location.pathname === "/") {
    //         dispatch(BaseActions.hideLoading());
    //     }
    // }, [])
    const [showPlay, setShowPlay] = React.useState(true);
    const [showPause, setShowPause] = React.useState(false);
    const [playing, setPlaying] = React.useState(false);
    const [replay, setReplay] = React.useState(false);
    const [videoEnded, setVideoEnded] = React.useState(false);

    const vidRef = React.useRef(null);

    const handlePauseVideo = () => {
        vidRef.current.pause();
        setShowPause(false)
        setShowPlay(true)
        setPlaying(false)
        setReplay(false)
        setVideoEnded(false)
    }

    const handlePlayVideo = () => {
        vidRef.current.play();
        setShowPause(true)
        setShowPlay(false)
        setPlaying(true)
        setReplay(false)
        setVideoEnded(false)
    }

    const handleVideo = () => {
        if (playing) {
            handlePauseVideo();
            setReplay(false);
        }
        if (!playing) {
            handlePlayVideo();
            setReplay(false);
        }
        if (videoEnded) {
            vidRef.current.play();
            setReplay(false);
            setShowPause(false)
            setShowPlay(false)
          
        }
    }

    const checkVideoEnded = () => {
        setReplay(true);
        setVideoEnded(true);
        vidRef.current.currentTime = 0;
        vidRef.current.load();
    };

    const handleReplayVideo = () => {
        vidRef.current.play();
        setReplay(false);
    }

    useEffect(() => {
        dispatch(BaseActions.updateLayout({ fullScreen: true }));
        if (location.pathname === "/") {
            dispatch(BaseActions.hideLoading());
        }
        if (vidRef.current) {
            const player = vidRef.current.children[0];
            if (player) {
                player.controls = true;
                player.playsinline = true;
                player.autoplay = false;
                setTimeout(() => {
                    const promise = player.play();
                    if (promise.then) {
                        promise
                            .then(() => { })
                            .catch(() => {
                                vidRef.current.style.display = "none";
                            });
                    }
                }, 0);
            }
        }
    }, [])

    return (
        <div>
            <Header />
            <div className={classes.background}>
                <div className={classes.background}>
                    <div className={classes.bannerContainer}>
                        <div className={classes.bannerContainerContent}>
                            <div className={classes.bannerContainerTextSize}>
                                <div className={classes.bannerHeadingMobile}>
                                    <div className="font-face-bold">
                                        <h1 className={classes.bannerHeading}>Monetize 15 Minute<br /> Video Calls</h1>
                                    </div>
                                </div>
                                <div className="font-face-regular">
                                    <h2 className={classes.bannerHeading2}>Make money doing 15 minute video calls. You handle you. We handle the scheduling, payments, video call… pretty much everything else.</h2>
                                </div>
                            </div>
                            <div>
                                <img src={iPhoneImage} className={classes.bannerImage} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10%', position: 'relative' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }} onClick={handleVideo}>
                                <video
                                    src="https://quartrlybucket-production.s3.us-east-2.amazonaws.com/uploads/quartrly_website_hype_video_2.mp4"
                                    // width={isMobile ? "400" : "1400"}
                                    // height={isMobile ? "200" : "700"}
                                    width={"90% !important"}
                                    height={"auto !important"}
                                    // controls="controls"
                                    // loop
                                    // muted
                                    // autoPlay
                                    ref={vidRef}
                                    // preload="metadata"
                                    poster={ThumbnailImage}
                                    onEnded={() => checkVideoEnded()}
                                    playsinline
                                    webkit-playsinline
                                />
                                <div className={classes.controlsWrapper}>
                                    <Grid container direaction="row" alignItems="center" justify="center" style={{ margin: "auto" }}>
                                        <div>
                                            <IconButton className={classes.controlIcons} style={{ display: showPlay ? "block" : "none" }} aria-label="reqind" onClick={handlePlayVideo}>
                                                {/* <PlayArrowIcon fontSize="inherit" /> */}
                                                <img style={{ width: isMobile ? "50%" : "100%" }} src={PlayButton} />
                                            </IconButton>
                                            <IconButton className={classes.controlIcons} style={{ display: showPause && !playing ? "block" : "none" }} aria-label="reqind" onClick={handlePauseVideo}>
                                                {/* <PlayArrowIcon fontSize="inherit" /> */}
                                                <img style={{ width: isMobile ? "50%" : "100%" }} src={PauseButton} />
                                            </IconButton>
                                            <IconButton className={classes.controlIcons} style={{ display: replay ? "block" : "none" }} aria-label="reqind" onClick={handleReplayVideo}>
                                                {/* <PlayArrowIcon fontSize="inherit" /> */}
                                                <img style={{ width: isMobile ? "50%" : "100%" }} src={ReplayButton} />
                                            </IconButton>
                                        </div>
                                    </Grid>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '3%' }}>
                        <div className={classes.contentContainer}>
                            <div className={classes.contentContainerMobile}>
                                <img src={AvailabilityImage} className={classes.contentImage} />
                            </div>
                            <div className={classes.contentTextContainerSize}>
                                <div className={classes.contentTextContainer}>
                                    <div className={classes.mobileHeading}>
                                        <div className="font-face-bold">
                                            <h1 className={classes.contentTextHeading}>Scheduling</h1>
                                        </div>
                                    </div>
                                    <div className="font-face-regular">
                                        <h1 className={classes.contentTextHeading2}>You define the days and times you are available for a Quartr. Your customer can see your availability. They can then book a time that’s convenient for them.</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={classes.contentContainer2}>
                            <div className={classes.contentTextContainerSize}>
                                <div className={classes.contentTextContainer}>
                                    <div className={classes.mobileHeading}>
                                        <div className="font-face-bold">
                                            <h1 className={classes.contentTextHeading}>Payments</h1>
                                        </div>
                                    </div>
                                    <div className="font-face-regular">
                                        <h1 className={classes.contentTextHeading2}>Your clients have credit cards? Of course they do! Book a Quartr using a major credit card.</h1>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.contentContainerMobile}>
                                <img src={PickadayImage} className={classes.contentImage} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={classes.contentContainer}>
                            <div className={classes.contentContainerMobile}>
                                <img src={VideocallsImage} className={classes.contentImage} />
                            </div>
                            <div className={classes.contentTextContainerSize}>
                                <div className={classes.contentTextContainer}>
                                    <div className={classes.mobileHeading}>
                                        <div className="font-face-bold">
                                            <h1 className={classes.contentTextHeading}>Video calls</h1>
                                        </div>
                                    </div>
                                    <div className="font-face-regular">
                                        <h1 className={classes.contentTextHeading2}>Not too long, but not too short. We feel that 15 minutes is enough time for 1 session. Need more time? Book a 2nd Quartr.</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={classes.contentContainer2}>
                            <div className={classes.contentTextContainerSize}>
                                <div className={classes.contentTextContainer}>
                                    <div className={classes.mobileHeading}>
                                        <div className="font-face-bold">
                                            <h1 className={classes.contentTextHeading}>Payouts</h1>
                                        </div>
                                    </div>
                                    <div className="font-face-regular">
                                        <h1 className={classes.contentTextHeading2}>We disburse payouts on a weekly schedule to your checking account. Don’t have a checking account? Um… sorry can’t help you there.</h1>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.contentContainerMobile}>
                                <img src={PaymentImage} className={classes.contentImage} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.Container}>
                    <div className={classes.bottomContainerContent}>
                        <div className={classes.bottomContainerTextContent}>
                            <div className="font-face-bold">
                                <h1 className={classes.contentTextHeading}>Who can use Quartrly?</h1>
                            </div>
                        </div>
                        <div className={classes.bottomContentGuidelines}>
                            <div className="font-face-regular">
                                <div className={classes.guidelines}> <img src={checkbox} /><p className={classes.guidelinesText}>Celebrities can use Quartrly to talk to their most loyal fans.</p></div>
                                <div className={classes.guidelines}> <img src={checkbox} /><p className={classes.guidelinesText}>Topic experts can use Quartrly to share knowledge with people seeking their expertise.</p></div>
                                <div className={classes.guidelines}> <img src={checkbox} /><p className={classes.guidelinesText}>Anyone can use Quartrly to provide value to another person via a 15 minute video call.</p></div>
                                <div className={classes.guidelines}> <img src={checkbox} /><p className={classes.guidelinesText}>We built Quartrly, but our users will tell us how they want to use it.</p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.justifyCenter}>
                    <div className={classes.justifyColumn}>
                        <div className="font-face-bold">
                            <h1 className={classes.contentTextHeading}>Quartrly available in 1 country</h1>
                        </div>
                        <div className={classes.justifyCenter}>
                            <div className={classes.justifyColumn}>
                                <img src={USA} className={classes.countryImage} />
                                <div className="font-face-bold">
                                    <h1 className={classes.countryName}>UNITED STATES</h1>
                                </div>
                            </div>
                        </div>
                        <div className="font-face-regular">
                            <p className={classes.vote}>Vote to make Quartrly available in your country!</p>
                        </div>
                        <div className={classes.justifyCenter}>
                            <div className="font-face-regular">
                                <button className={classes.voteButton} onClick={() => history.push("/vote")}>Vote for your country</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default Home;
