import "./index.css"
import React, { useState } from "react"
import Popover from '@material-ui/core/Popover'
import LanguageIcon from '@material-ui/icons/Language'
import { makeStyles, Typography } from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import * as BaseActions from "store/actions";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect"
const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    // marginTop: 40,
    padding: 17,
    fontWeight: 'bold',
    [theme.breakpoints.down("xs")]: {
      padding: "15px 17px 0px 17px",
    }
  },
  headerText: {
    color: '#31A7DB',
    fontSize: '1.9rem',
    fontFamily: 'Cerebri-Bold'
  },
  leftSideMenu: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  },
  menuIcon: {
    width: 18,
    cursor: 'pointer',
    marginRight: '1rem',
  },
  icon: {
    width: 50,
    height: 50,
    cursor: 'pointer',
    marginRight: '1rem',
  },
  backgroundIcon: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: 300
  },
  inviteHeading: {
    display: 'flex',
    flexDirection: 'column',
    padding: 25,
    color: "#31A7DB",
    fontFamily: "Cerebri-SemiBold",
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      padding: '15px 25px 25px 25px'
    }
  },
  BottomWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: 25,
    fontWeight: 'bold',
  },
  BottomCode: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    height: 50,
    color: '#fff',
    borderRadius: 6,
    border: '1px dashed #31A7DB',
    fontFamily: "Cerebri-SemiBold",
    marginRight: 20,
    letterSpacing: 8,
    fontSize: '1.5rem'
  },
  backButton: {
    left: 20,
    // backgroundColor: "rgba(0,0,0,0.4)",
    // borderRadius: 6,
    // paddingLeft: 6,
    color: "white",
    cursor: "pointer",
  },
  languageButton: {
    paddingLeft: 6,
    color: "white",
    cursor: "pointer"
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
    // padding: '10px 20px'
  }
}))
const TermsOfService = ({ history }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: false }));
  }, []);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null)
  const [language, setLanguage] = useState('english')
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined
  // const userId = queryString.parse(history.location.search).id
  // const code = queryString.parse(history.location.search).code
  const handleGoBack = () => {
    if (window.location.search.includes('from=legal')) {
      history.push('/legalStuff')
    } else if (window.location.search.includes('from=tandc')) {
      history.push(`/tandc`)
    } else if (window.location.search.includes('from=signup')) {
      history.push(`/signup`, data)
    } else {
      history.push('/home')
    }
  }
  // \/\/\/\/\/\/\/\/\/\/
  //checking sign up form values when we moved from sign up to terms 
  //we will use them, when we go back to sign to form
  let data = [];
  if (history && history.location && history.location.state) {
    const firstname = history.location.state[0]
    const lastname = history.location.state[1]
    const email = history.location.state[2]
    data = [firstname, lastname, email]
  }
  // /\/\/\/\/\/\/\/\/\/\
  return (
    <div className="body">
      <div style={{ paddingTop: isMobile ? '0px' : '13px' }}></div>
      <div id="english">
        <div className={classes.headerWrapper}>
          <div className={classes.leftSideMenu}>
            {/* <img src='assets/Tabbar/TabbarInactive/menu.png' className={classes.menuIcon} onClick={() => dispatch(BaseActions.showMessage(<DrawerMenu />))} /> */}
            <ArrowBackIosIcon
              className={classes.backButton}
              onClick={handleGoBack}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={classes.popover}>
                <span className="hover" style={{ padding: '10px 20px 5px 20px' }}>
                  <Typography variant='caption' onClick={() => setLanguage('english')}>English</Typography>
                </span>
                <span className="hover" style={{ padding: '4px 20px 4px 20px' }}>
                  <Typography variant='caption' onClick={() => setLanguage('french')}>French</Typography>
                </span>
                <span className="hover" style={{ padding: '5px 20px 10px 20px' }}>
                  <Typography variant='caption' onClick={() => setLanguage('spanish')}>Spanish</Typography>
                </span>
              </div>
            </Popover>
            <Typography variant='h5' className={classes.headerText}>Terms of Services</Typography>
          </div>
          <LanguageIcon className={classes.languageButton} onClick={handleClick} />
        </div>
        {
          language === 'english' && <English />
        }
        {
          language === 'french' && <French />
        }
        {
          language === 'spanish' && <Spanish />
        }
      </div>
    </div >
  )
}
export default TermsOfService
function English() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Introduction
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Welcome, these are the Terms and Conditions of Service (the “Terms”) for the mobile application known as Quartrly (the“App”, ) and the website located at www.quartrly.com (the “Site”), including its sub-domains and its mobile-optimized versions. The Site and the App proudly operated by Quartrly, Inc. (“us”, “its”, “ours”, “we”) This Terms govern the relationships between user or visitor (“you”, “your”, “yourself”) and Quartly, Inc. regarding use of App and Website. Quartrly, Inc. reserves the right to modify, amend, suspend, terminate, upgrade, update or otherwise modify the Services, the Site and the App, at any time and without notice. Any changes to these Terms will be displayed on the App and the Site, and we may notify you through the Site, the App or by email. Please refer to the last effective date where changes were last undertaken by us. Your use of our services after the effective date of any update– either by an account registration or simple use – thereby indicates your acceptance thereof.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Acceptance
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          BY REGISTERING FOR, ACCESSING, BROWSING, DOWNLOADING FROM OR USING APP OR WEBSITE, ALONG WITH ANY PRODUCTS AND SERVICES OFFERED THEREBY (THE “SERVICE(S)”) YOU AGREEING THAT YOU HAVE READ AND AGREE TO FULLY COMPLY WITH AND BE FULLY BOUND BY THIS TERMS OF SERVICE AND PRIVACY POLICY (THE “POLICY”), INCORPORATED HEREIN BY REFERENCE. THESE TERMS CONSTRUE ENTIRE AGREEMENT BETWEEN YOU AND US.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          YOU ASSERT THAT YOU ARE OF LEGAL AGE (18 YEARS OLD), AND YOU ARE HAVING THE POWER AND COMPETENT TO ENTER INTO AND TO COMPLY WITH THESE TERMS. IF YOU ARE NOT OF LEGAL AGE PLEASE DO NOT USE OUR SITE OR APP.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          General User Account Registration, Verification and Safety
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          In order to use Quartrly, you must create an account of a user and provide information requested. Your user profile must include a real photograph and your real first and last name. You may need to provide, and authorize us to access, the requested social media sign-on, along with access to personal information such as friend lists, email, image gallery, likes and other information. If you close your social media account, or if you deauthorize our access, your account with us may close.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          By granting us access to your social media account, you accept and understands that we shall be entitled to access and store certain information regarding you as described herein and in our Policy. Currently social media account login function support only Facebook. For this purpose, you are allowing the Quartrly to access your Facebook account information and you are agreeing to the Facebook Terms of Service (link https://www.facebook.com/terms.php) in your use of the Quartrly.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          You are solely responsible for the activity that occurs on your Quartrly account and for keeping your password secure and confidential, and must notify us immediately of any breach or unauthorized use of your account. You will be liable for any and all misuse of your account, including the fraudulent use by a third party, or even the disclosure of your password to a third party.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Your access to Quartrly is only offered for your individual use, and not for the use or benefit of any third party you may represent.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Services
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          We provide an App where user can request to communicate with other user registered. Quartrly allows real-time communication among users by means of a video call interface with using of booking functionality .
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          General user are able to search for other user in order to request multimedia call bookings with them (each, a “Quartr”). User requesting a Quartr are able to see the user profile, availability, proposed fee and other items.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          By default, Quartrs video call last for 15 minutes. User hosting a Quartr (the “Host”) must indicate their availability by date and time slots. If a user wishes to book a Quartr (the “Attendee”), they have to request a time slot that is available.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Quartr Booking Process
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartr Appointment. Attendee can review the future availability of Hosts, and once a date is selected, the App will show the 15-minute blocks that are available for Quartrs. The countdown timer starts when the server attempts to connect both parties and continues to run even if one party loses their network connection.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Available Now Calls. Attendee can book an immediate Quartr with a user that has set their Availability to Available Now. After booking a Quartr with a successful payment transaction, the App will match the users.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          As a general rule, we do not review, moderate or select the Quartr requests, audio, video, background elements, proposals and text messages. Notwithstanding the foregoing, we reserve the right to do so, including recording each Quartr’s content, at our sole and final discretion.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Quartr User Earnings
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Each user is free to define their Quartr rate, and they will have the right to update it up to four (4) times per month. Payments are processed via the App and our selected payment processing partners. Attendees are charged the Quartr cost, and other applicable fees, before each call is initiated, in any case. Funds paid to the Host will go into a holding escrow account and will not be disbursed to the other user until the Quartr call has finished being fulfilled. The earnings will be disbursed to the user based on our current disbursement schedule (typically weekly) - with disbursements being greater than or equal to $100.00 US Dollars.We may, at our own discretion make changes in disbursement schedule due to reasonable cause.Funds will then be sent to the Host.
          <br />
          <br />
          Except as expressly set forth herein, all charges are final and non-refundable. If we detect any chargeback or if any payment is not received by us for any reason from your card or account, you will promptly pay us any and all amounts due to us upon notice. Any failure or inability by us to process any payment hereunder does not relieve you from your payment obligations to Quartrly.
          <br />
          <br />
          There will be a 10% cancellation fee charged to the user who cancels a Quartr Appointment at least twenty four (24) hours prior to the date on which a Quartr is scheduled to commence.
          <br />
          <br />
          There will be a 50% cancellation fee charged to the user who cancels a Video Call Appointment within the twenty-four (24) hours prior to the Quartr’s appointment time.
          <br />
          <br />
          The aforementioned cancellation charges will be applied to: (i) Attendees via their payment processing account; and (ii) Hosts against their future earnings or, if there are no earnings on their account, to their payment processing account.
          <br />
          <br />
          If a user commences a Quartr and is unable to continue the Quartr (e.g. Internet connection issues, the smartphone loses battery power, the user closes the App by accident), the users will have a one minute term to attempt to re-establish connection; after which the Quartr will be cancelled.
          <br />
          <br />
          If the Attendee loses the connection, he will be charged the full Quartr fee. If the Host loses the connection, the Attendee will only be charged the prorated Quartr fee up to the moment when the connection was lost. Gateway fees of approx. 3% is not refundable to either user, even when the user cancels their appointment on time.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Service Disclaimer
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartrly does not provide the actual communication with other users, only the platform for video call and other types of communication, and hereby disclaims any and all representation or warranty about the contacted users, their likelihood to similar persons and personalities, their text, audio, photo and video content messages, and for the actual performance, personal likeness, skill or availability of the contacted users. Any video calls which you receive are not intended to purport any identifiable person. For example, if you receive a call or message by someone with username “John Smith”, such call is not guaranteed to represent any specific person, and you will not be able to infer the actual location, address, telephone or personal information of any user, unless the user elects to disclose it to you.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Account Suspension & Termination
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartrly encourages you to report violations of our Terms. Users undertaking conducts that may constitute a factual –or even alleged– breach of these Terms, including but not limited to, using automated mechanisms to make fraudulent communications, may become subject to immediate account suspension / termination, at our sole and final discretion, without notice and without responsibility.
          <br />
          <br />
          We reserve the right, at our sole and final discretion, to deactivate, freeze, suspend or terminate any account and/or any event, upon any factual or alleged breach of these Terms. You must notify us immediately of any change in your eligibility to use the Services, or if you suspect a breach or unauthorized use of your account.
          <br />
          <br />
          You acknowledge and agree that we may report any activity that we believe may violate any law to law enforcement, regulators or other relevant third parties, and that any violation of the aforementioned provisions may result in the immediate termination of your access to our Services.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          General Payment Terms
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          You must provide us valid and current billing information. Except as expressly set forth herein, all purchases made through Quartrly are final and non-cancelable or non-refundable.
          <br />
          <br />
          Quartrly charges a percentage of the Host´s Quartr calling rate		 (Service Fee), as indicated to user during Quartr booking and fee for performance of payment transaction (Transaction fee
          ).
          <br />
          <br />
          Quartrly charges you a Service Fee based on your permanent user identification number, assigned according to you when you create account with Quartrly. The Service Fee rates are as follows:
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th style={{ textAlign: 'start' }}>Número de identificación del usuario (según secuencia de Quartrly creación de cuenta)	</th>
              <th>Service Fee % rates
              </th>
            </tr>
            <tbody>
              <tr>
                <td>First 1000 users</td>
                <td>7.5%</td>
              </tr>
              <tr>
                <td>1001 - 11 000</td>
                <td>9.5%</td>
              </tr>
              <tr>
                <td>11 001 - 111 000</td>
                <td>11.5%</td>
              </tr>
              <tr>
                <td>111 001 - 1 111 000</td>
                <td>12.5%</td>
              </tr>
              <tr>
                <td>1 111 001 - 11 111 000</td>
                <td>13.5%</td>
              </tr>
              <tr>
                <td>11 111 001 - 111 111 000</td>
                <td>14%</td>
              </tr>
              <tr>
                <td>111 111 001 and over</td>
                <td>14.5%</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Example 1: Service Fee calculation
          <br />
          User A who downloaded Quartrly and created account, received identification number 1023. User B downloaded Quartrly and created account later, and received number 111 032. Service Fee will be as as follows.
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th>User number (according to sequence of Quartrly account created)</th>
              <th>Service Fee % rates
              </th>
            </tr>
            <tbody>
              <tr>
                <td>User A</td>
                <td>7.5%</td>
              </tr>
              <tr>
                <td>User B</td>
                <td>12.5%</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartrly charge you a standard Transaction fee
          of 6% from Quartr calling rate		 equal to $10 or higher. For Quartrs that have calling rate of $9 or lower, Quartrly will apply a fixed amount as scheduled below:
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th>Quartr calling rate		</th>
              <th>Transaction fee
              </th>
            </tr>
            <tbody>
              <tr>
                <td>$1</td>
                <td>$0.34</td>
              </tr>
              <tr>
                <td>$2</td>
                <td>$0.39</td>
              </tr>
              <tr>
                <td>$3</td>
                <td>$0.43</td>
              </tr>
              <tr>
                <td>$4</td>
                <td>$0.48</td>
              </tr>
              <tr>
                <td>$5</td>
                <td>$0.52</td>
              </tr>
              <tr>
                <td>$6</td>
                <td>$0.56</td>
              </tr>
              <tr>
                <td>$7</td>
                <td>$0.61</td>
              </tr>
              <tr>
                <td>$8</td>
                <td>$0.65</td>
              </tr>
              <tr>
                <td>$9</td>
                <td>$0.70</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Example 2: Transaction fee
          calculation
          <br />
          If User C calling rate is $2, User D calling rate is $5, and User E calling rate is $15, the Transaction fee
          will be as follows:
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th>Quartr calling rate		</th>
              <th>Transaction fee
              </th>
            </tr>
            <tbody>
              <tr>
                <td>User C</td>
                <td>$0.39</td>
              </tr>
              <tr>
                <td>User D</td>
                <td>$0.52</td>
              </tr>
              <tr>
                <td>User E</td>
                <td>$0.90 ($15 * 6%)</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartrly hereby reserves the right to amend, adjust and change such Service Fee and Transaction fee
          from time to time, at its sole and final discretion. If we detect any chargeback, or if any payment is not received by us for any reason from a credit card or account, you will promptly pay us any and all amounts due to us upon notice. Any failure or inability by us to process any payment hereunder does not relieve you from your payment obligations.
          <br />
          <br />
          You hereby authorize us the charging of your credit card or payment processing account.
          <br />
          <br />
          We use worldwide accepted third-party payment processors to bill you through a payment account linked to your account with Quartrly. The processing of payments will be subject to the fees, terms, conditions and privacy policies of such payment processor. Quartrly is not responsible for any and all errors, fees and currency conversions fees by the payment processor, and you should review its terms and policies from time to time, which will govern the provision of services to you.
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Refund
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Your satisfaction with our Services is one of our main goals. Due to this, we are providing a refund in a certain situation according to this section. In any refund case, we charge $0,30 refund fee. You must contact us within 30 days of charge in order to receive a refund. No refunds will be given for any transaction which is more than 30 days old.
          <br />
          <br />
          If Host missed a Quartr call, we will provide full refund to Attendee.
          <br />
          <br />
          If Attendee missed Quartr call, no refund shall be provided.
          <br />
          <br />
          If Host missed part of the Quartr call, we do a pro-rated refund based on time missed. We will provide a refund from all fees as scheduled below:
          <br />
          <br />
          Example 3: Partial refund calculation.
          <br />
          <br />
          If Host fee is $20 and Service fee is 10%, the refund will be as follows:
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)		</tr>
                <td>1</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>93.33%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$18.67</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$1.87</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$1.12</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.19</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>2</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>86.67%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$17.33</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$1.73</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$1.04</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.17</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>3</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>80%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$16</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$1.60</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.96</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.16</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>4</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>73.33%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$14.67</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$1.46</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.88</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.15</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>5</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>66.67%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$13.33</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$1.33</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.80</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.13</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>6</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>60%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$12</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$1.20</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.72</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.12</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>7</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>53.33%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$10.67</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$1.06</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.64</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.11</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>8</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>46.67%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$9.33</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$$0.93</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.56</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.09</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>9</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>40%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$8</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$0.80</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.48</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.08</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>10</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>33.33%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$6.67</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$0.66</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.40</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.07</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>11</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>26.67%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$5.33</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$0.53</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.32</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.05</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>12</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>20%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$4</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$0.40</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.24</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.04</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>13</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>13.33%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$2.67</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$0.26</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.16</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.03</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Quartr length (minutes)</tr>
                <td>14</td>
              </tr>
              <tr>
                <tr>Refund %	</tr>
                <td>6.67%</td>
              </tr>
              <tr>
                <tr>Quartr calling rate ($20) refunded	</tr>
                <td>$1.33</td>
              </tr>
              <tr>
                <tr>Service fee (10%) refunded			</tr>
                <td>$0.13</td>
              </tr>
              <tr>
                <tr>Transaction fee (6%) refunded		</tr>
                <td>$0.08</td>
              </tr>
              <tr>
                <tr>Referral Fee (1%)
                </tr>
                <td>$0.01</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Refund is calculated down to the seconds.
          <br />
          <br />
          If refund request arises due to our failure to provide the Services outlined in these Terms, we shall provide full refund.
          <br />
          <br />
          If you believe that we have charged you in error, you must contact us within 30 days of such charge. No refunds will be given for any transaction which is more than 30 days old. We reserve the right to refuse a refund request if we reasonably believes or suspects (i) that you are trying to unfairly exploit the provisions of this section; (ii) that you are in breach of the terms of Terms of Service; (iii) that you are using Quartrly fraudulently or that your Quartrly user account is being used by a third party fraudulently.
          <br />
          <br />
          For all refunds due to an error in your payment amount please email support@quartrly.com quoting your address, full name, contact number, and reason for requesting a refund. We will try to deal with your request at the earliest possible opportunity.
          <br />
          <br />
          Please note that we may request additional information in order to process your refund request. Please note that non-provision of such information shall be a ground for refund refusal.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          User Referral Program
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          When registering into Quartrly, each user will be assigned a username code. This is a 6 character code that can be shared with other persons in order for the referrer to receive one percent (1%) of the net, actual earnings of the referee, as long as both users have a valid account with Quartrly.
          <br />
          <br />
          Accordingly, the receiving user may use it when activating his account in Quartrly and, in turn, receive his percentage of the earnings of the referring user. For the avoidance of doubt, each user code may be shared with multiple users, but each individual registering user will only be able to use it once when registering, and not later.
          <br />
          <br />
          We will notify you if your code application has been successful and in such case you will become part of our referral program. All affiliate activities must be conducted in a professional and proper manner. You shall be expected to act with good faith and integrity in your relationships with your referrals, and always act in the best interests of your referrals. You shall avoid disclosure or unauthorized use of any confidential information that may come to your knowledge or possession to third persons or external parties.
          <br />
          <br />
          Quartrly shall pay you the revenues detailed on the referral page of your account. Quartrly reserves the right to disregard duplicate accounts or nominee accounts that Quartrly sees as non-bona fide, at its sole discretion. Quartrly may provide you with statements accessible through an electronic system detailing the revenues and charges generated by users you have referred, if any, which have accrued to you over the course of each calendar month. The referral payment terms will be subject to the third-party affiliate platform of our choice. Please read and become familiar with such terms.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Access to Quartrly
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Only a copy of the App subject to a valid license from Quartrly can be used to access the Services, and to download updates and patches. If you transfer or sell your authorized device to another person, you have to close your session with Quartrly and delete your copy of the App. You may not transfer or assign any of your rights under these Terms.
          <br />
          <br />
          The App requires an Internet connection to access some or part of its features and functionalities, to authenticate the App, to sign-in or otherwise to perform other functions.
          <br />
          <br />
          In order for certain features of the App to operate properly, you may be required to have and maintain a: (i) valid and active account with a wireless mobile data service; (ii) modern, fast and adequate mobile device with the required specifications in order to run the App; and/or (iii) valid and active credit or debit card or payment processing platform account. If you do not have such items, then the App or certain features thereof may not operate, be available or cease to function properly, either in whole or in part, and therefore Quartrly will not be held liable for any claim, injury or damage arising out of or in connection therefrom.
          <br />
          <br />
          You acknowledge and agree that you will be solely responsible for any fees, including Internet connection or mobile fees, that you incur when accessing, downloading, browsing or using App.
          <br />
          <br />
          You may not interfere with any access control measures or attempt to disable or circumvent such security features. If you disable or otherwise tamper with the technical protection measures, the App will not function properly and these Terms will terminate immediately, and henceforth Quartrly will not be held liable for any claim, injury or damage arising out of or in connection therefrom.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Content moderation
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          We encourage users to report violations of our Terms. Each user is solely responsible for their activities and any content posted, transmitted or otherwise made available via our Services.
          <br />
          <br />
          It is our right, but not the obligation, to monitor any Quartr activity and ‘user generated content’ (e.g. names, photos, posts, feedback, images, comments, questions and other content) to determine compliance thereof, and to edit, refuse to post or remove any material or content submitted to or posted on our Services that we find to be in violation of our Terms, Policy or that is otherwise objectionable.
          <br />
          <br />
          You acknowledge and agree that we may report any activity that we believe may violate any law to law enforcement, regulators or other relevant third parties, and that any violation of the aforementioned provisions may result in the immediate termination of your access to Quartrly and our Services.
          <br />
          <br />
          We reserve the right (but not the obligation) to monitor disputes between you and other of our users. We also reserve the right (but not the obligation) to delete items, products, services and user-generated content that would be interpreted or considered offensive or spam.
          <br />
          <br />
          You must immediately respond to any notice you receive claiming that your user-generated content violates a third party’s rights, including notices under the Digital Millennium Copyright Act (‘DMCA’), and take corrective action, which may include but is not limited to promptly removing any such user-generated content.
          <br />
          <br />
          You also acknowledge and accept that any violation of the aforementioned provisions may result in the immediate termination of your access to Quartrly and our Services, without refund or reimbursement on our part. Quartrly may terminate or suspend your account and/or membership for our Service, at any time, for any reason, without prior notice, and at our sole and final discretion.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Prohibited Activities
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          As our user, you agree to not undertake, motivate, or facilitate the use or access of App or Site in order to:
          <br />
          a- Infringe these Terms, or allow, encourage or facilitate others to do so.
          <br />
          b- Plagiarize and/or infringe the intellectual property rights or privacy rights of any third party, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right.
          <br />
          c- Distribute, post or otherwise make available any content that: (i) infringes or endangers the intellectual property rights of any person (e.g. trademark, moral rights, trade secret, copyright, ancillary rights or other); (ii) enables any act that could promote or cause discrimination, racism, harm, libel, hatred or violence against any individual or group; (iii) endangers children and underage persons; (iv) is or allows illegal or fraudulent activities to take place; (v) is or may constitute a criminal or capital offense or otherwise infringes any applicable law; (vi) is or can be considered to be nudity, erotic, obscene, threatening/defamatory, plagiarized, firearms, tobacco, alcohol, marijuana, gambling, pornographic or analogous material; (vii) providing informational support or resources, concealing and/or disguising the character, location, and or source to any organization delegated by the United States government as a “foreign terrorist organization” in accordance to Section 219 of the Immigration Nationality Act.
          <br />
          d- Collect, receive, transfer or disseminate any personally identifiable information of any person without due consent from the title holder.
          <br />
          e- Solicit money or any digital or physical goods from other users.
          <br />
          f- Ask other users to conceal the identity, source, or destination of any illegally gained money or products.
          <br />
          g- Upload infringing, false, duplicated, altered or otherwise misleading purchase information.
          <br />
          h- Use any automated or manual process to obtain, copy, process, access and/or use our Services or any part therefrom to capture unauthorized data or content, for any purpose.
          <br />
          i- Enable, undertake, program or execute any type of system, computer program or technique in order to data-mine, retrieve, scrape, index or otherwise extract unauthorized information from App or Site or any portion or data feeds therefrom. For purposes of clarification, such actions will include the use of persons, site search/retrieval applications, software ‘robots’ and ‘spiders’ and any analogous data gathering and extraction tools, regardless of the type and amount of information intended for extraction.
          <br />
          j- Use App or Site to disseminate any type of computer viruses, worms, defects, Trojan horses or other items of a destructive nature.
          <br />
          k- Undertake any action that will or may cause an unreasonable overload on App or Site and/or its partners’ and affiliates’ technology or infrastructure, or otherwise make excessive traffic demands from App or Site.
          <br />
          l- Intercept or monitor activity via our Services without our express authorization.
          <br />
          m- Access the App or Site with intent and/or in order to create a comparable or similar application or website or copy any features, graphics, ideas, images, videos, intellectual property or functions of the App or Website;
          <br />
          n- Otherwise reverse engineer, decompile or extract the proprietary code of App or Site.
          <br />
          <br />
          You also acknowledge and accept that any violation of the aforementioned provisions may result in the immediate termination of your access to Quartrly and our Services, without refund or reimbursement on our part. We may terminate or suspend your account and/or membership for our Service, at any time, for any reason, without prior notice, and at our sole and final discretion.
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          App Store Terms
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          You hereby acknowledge and agree that these Terms are executed between Quartrly, Inc. and you, and not between Google, Inc. or Apple, Inc. (hereinafter, “Apple” and “Google”). Henceforth, the license granted to you for the App is also limited to a non-transferable license to use the App by authorized third party device activated product that you own or control, and as permitted by the terms of use set forth in the applicable app store.
          <br />
          <br />
          These Terms may not provide for usage rules for the App that are less restrictive than the usage rules set forth for licensed applications in, or that otherwise are in conflict with, Google’s or Apple’s app store's terms of service.
          <br />
          <br />
          Quartrly, and not any such third party app store provider, shall be solely responsible for the App and any and all content thereof. We are solely responsible for the App, the Services and the content thereof.
          <br />
          <br />
          To the extent these Terms provide for terms of use or licenses for the App that are less restrictive than the terms of use set forth in the applicable app store, or that otherwise are in conflict with their respective use, the more restrictive term shall govern.
          <br />
          <br />
          We are solely responsible for providing any maintenance and support services with respect to the App, and neither Apple nor Google have any obligation to furnish any maintenance and support services thereof.
          <br />
          <br />
          In the event of any failure of the App to conform to any applicable warranty, you may notify Apple or Google, and they may refund you the purchase price for the App only (if any); and, to the maximum extent permitted by applicable law, neither Apple nor Google will have other warranty obligations whatsoever with respect to the App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be Quartrly’s responsibility.
          <br />
          <br />
          You hereby acknowledge and agree that Apple and Google (and their affiliates), are third party beneficiaries of these Terms, and that, upon your acceptance of the terms and conditions of these Terms, Apple and Google will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as third party beneficiaries thereof.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Ownership
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          The trademarks, copyright, service marks, trade names and other intellectual property rights and proprietary notices displayed on Quartrly are the property of –or otherwise are licensed to– Quartrly or its licensors or affiliates, whether acknowledged (or not), and which are protected under intellectual and proprietary rights in the United States of America and other jurisdictions throughout the world. Respective title holders may or may not be affiliated with us or our affiliates, partners and advertisers. No section hereof shall be construed as intent to grant to you any right or interest in Quartrly or our Services, in whole or in part.
          <br />
          <br />
          For ease of understanding, ‘intellectual property rights’ shall mean any and all patent rights, copyright rights, mask work rights, moral rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret rights and other intellectual property rights as may now exist or hereafter come into existence, and all applications therefore and registrations, renewals and extensions thereof, under the laws of the United States of America and other applicable jurisdictions.
          <br />
          <br />
          You acknowledge and agree that any and all infringing use or exploitation of copyrighted content in Quartrly and/or the Services may cause us, our affiliates, licensors or content providers irreparable injury, which may not be remedied solely at law, and therefore our affiliates, licensors or content providers may seek remedy for breach of these Terms, either in equity or through injunctive or other equitable relief.
          <br />
          <br />
          You hereby acknowledge and agree that, in the event of any third party claim that the App infringes any third party’s intellectual property rights, Quartrly, and not Google Inc. or Apple Inc., will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Term, Termination
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          The term hereof shall begin on the date that comes first among: (i) first access to the Site; (ii) your first access or execution of our App; or (iii) Quartrly begins providing its Services to you.
          <br />
          <br />
          The term hereof will automatically end on the earlier date of either your: (i) account deactivation, suspension, freezing or deletion of the App; (ii) your access termination or access revocation; (iii) Quartrly 's termination of these Terms, at its sole and final discretion; (iv) the termination date indicated by Quartrly to you from time to time; or (v) our decision to make the Site or App no longer available for use, at its sole and final discretion.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Licenses
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          From the moment of acceptance of this Terms, Quartrly, Inc. grants to you individual, non-sublicensable, non-transferable, revocable non-perpetual limited non-exclusive license subject to the limitations below to download, access and use the App for your individual purpose. Unless otherwise specified by Quartrly, Inc., you are not permitted to reproduce, copy, modify, distribute, transmit, display, publish, license, create derivative works from the App or any its part. All rights not expressly granted in this Terms of Use are reserved by Quartrly, Inc.
          <br />
          <br />
          If you downloaded the Quartrly from Apple App Store, you hereby assert and agree that you will only use the Quartrly (i) on an Apple-branded product that runs iOS (Apple’s proprietary operating system) and (ii) as permitted by the “Usage Rules” set forth in the Apple App Store Terms of Service.
          <br />
          <br />
          User Generated Content License. You hereby grant Quartrly an unlimited, non-exclusive, royalty-free, for all the world, right and license to download, use, reproduce, distribute, analyze and exploit any and all content, texts, data and any components therefrom that you or your affiliates, agents, employees or representatives introduce, upload or otherwise deliver to Quartrly. You represent and warrant to Quartrly that you have all rights, authorizations or otherwise hold sufficient title for all content submitted to Quartrly as set forth herein.
          <br />
          <br />
          As used herein or within the App, ‘Open Source’ shall mean open source software provided within the App that is licensed or sub-licensed to you under the terms and conditions of the applicable license agreements or copyright notices included within such Open Source component. These Terms do not apply to nor govern any Open Source accompanying the App and Quartrly hereby disclaims any and all warranties, representations and liability to you or any third party related thereto.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Third party links
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          From time to time, the Site, the App and/or the Services may contain hyperlinks to other websites. These links are for your personal convenience and to provide you with further information which may be of interest to you. The provision of such links does not imply any endorsement of such third party websites (or their products and services), owned by third parties. For avoidance of doubts, these Terms shall in no case govern use of third party websites, accessed through third party links.
          <br />
          <br />
          From time to time, we may place ads and promotions from third-party sources in the Site, the App and/or the Services. Accordingly, your participation or undertakings in promotions of third parties other than Quartrly, and any terms, conditions, warranties or representations associated with such undertakings, are solely between you and such third party. Quartrly is not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of third party advertisers.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          User Privacy
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          By disclosing any information to us, you agree to the terms of our Policy, including the terms for the collection, process, storage and disclosure of such personally identifiable information, including to our affiliates, partners and clients. We will ask for your express consent, including for inclusion into our newsletters, updates, and follow-ups. For more information, please read our Policy.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Digital Millennium Copyright Act (‘DMCA’) Notice
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          For DMCA Takedown Notices and alleged intellectual property rights infringement within Quartrly, you can get in touch with us as indicated in our contact section. Under federal United States law, if you knowingly misrepresent that online material is infringing, you may be subject to criminal prosecution for perjury and civil penalties, including monetary damages, court costs, and attorney's fees.
          <br /><br />
          You must notify us of your claim with subject: "Takedown Request". Once received, Quartrly will study and consider your claim and, if it believes or has reason to believe any content on Quartrly infringes on another’s copyright, Quartrly may delete it, disable or otherwise stop displaying it. Please note that this procedure is exclusively for notifying Quartrly and its affiliates that your copyrighted material has been infringed.
          <br /><br />
          Your DMCA notification claim must be in writing and must at least contain the following information: (i) your signature and identification, or the ones the person authorized to act on behalf of you or the title holder; (ii) a clear and concise description of the content you allege is a copyright infringement; (iii) contact information (e.g. address and email); and (iv) a statement, under penalty of perjury, indicating that you have a good faith belief that the information provided in your claim is true and accurate. The preceding requirements are intended to comply with Quartrly’ rights and obligations under the DMCA, including 17 U.S.C. 512(c), but do not constitute legal advice.
          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          No Warranty
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Neither Quartrly, Inc., nor its affiliates, subsidiaries, officers, employees and agents guarantee that Quartrly will be error-free, uninterrupted, secure, or produce any particular results. No advice or information given by Quartrly, Inc. or its employees, affiliates, contractors and/or agents shall create a guarantee. Quartrly has not been completely tested in all situations or devices, and Quartrly may or will contain operational malfunctions, errors, viruses, bugs, worms, trojan horses, bots and other harmful and destructive components or defects.
          <br /><br />
          Neither Quartrly , Inc. nor its affiliates, licensors, owners, subsidiaries, brands or advertisers are a professional advisor in any industry. The functionalities and results displayed within Quartrly may or will vary, without prior notice or without any notice at all, and you may receive Quartrs, or not, depending on the availability of other users and/or functionalities of Quartrly, and any results will depend on a variety of factors out of the ultimate control of Quartrly Your use of any information and/or materials on Quartrly is entirely at your own risk, for which we shall not be held liable.
          <br /><br />
          To the fullest extent permissible under applicable law, the App and Site provided to you “as is”, with “all faults” and “as available”, with all faults, without warranty of any kind, without performance assurances or guarantees of any kind, and your use is at your sole risk. No oral or written advice provided by Quartrly, Inc., its affiliates, clients, agents, officers, licensors, distributors and/or any authorized representative, shall create any implied warranty. The entire risk of satisfactory quality and performance resides with you. Quartrly, Inc. and its affiliates, clients, agents, officers, licensors and/or distributors, do not make, and hereby disclaim, any and all express, implied or statutory warranties, either by statute, common law, custom, usage of trade, course of dealing or otherwise, however arising, including implied warranties of description, quality, fitness for a particular purpose, operation, integration, adequacy, suitability, title, non-infringement, non-interference with use and/or enjoyment.<br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Disclaimer of Damages
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          In no event shall Quartrly, Inc., its affiliates, clients, agents, officers, licensors, distributors and/or any authorized third party, be held liable for any special, indirect, incidental or consequential damages, including losses, costs or expenses of any kind resulting from possession, access, use or malfunction of App or Site, including but not limited to, loss of revenue, profits, business, loss of use or lack of availability of computer resources, lost or corrupt data, re-procurement amount, anticipated savings, wasted expenditure, or other commercial or economic loss; or for any indirect, incidental, special, punitive, aggravated, exemplary, or consequential damages whatsoever arising out of or related to the App or Site, whether arising in tort (including negligence), contract, strict liability or other legal or equitable theory and whether or not Quartrly , Inc., its affiliates, clients, licensors and/or distributors have been advised of the possibility of such damages. For purposes of this limitation of liability, Quartrly, Inc.’ affiliates, licensors and distributors are third party beneficiaries to the limitations of liability specified herein and they may enforce these Terms against you.
          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Limitation of Liability
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          In no event, shall Quartrly’, Inc., or its affiliates’, clients’, licensors’ and/or distributors’ liability for all damages (except as required by applicable law) exceed the amount of USD$50.00 (Fifty United States Dollars); and henceforth any award for direct, provable damages shall not exceed such total amount. These Terms provide you specific legal rights, and you may have other rights that may vary from jurisdiction to jurisdiction. Legislation of some states/countries does not allow certain limitations of liability, and henceforth this limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. This limitation of liability shall not be applicable solely to the extent that any specific provision of this limitation of liability is prohibited by any federal, state, or municipal law, which cannot be preempted.
          <br /><br />
          As our user, you hereby acknowledge and agree that Google Inc. and Apple Inc., and their affiliates and subsidiaries, are third party beneficiaries of these Terms, and that, upon your acceptance of the terms and conditions hereof, they will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary thereof.
          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Indemnification
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          You shall indemnify, hold harmless, and defend Quartrly, Inc. its affiliates, clients, agents, officers, licensors, distributors and/or any authorized representatives, and the officers, directors and employees of each (jointly, the “Quartrly’ Indemnitees”) from and against any and all third party liabilities, claims, causes of action, suits, losses, damages, fines, judgments, settlements and expenses (including any and all reasonable outside attorneys’ fees and court costs) which may be suffered, made or incurred by any of such Quartrly’ Indemnitees arising out of or relating to: (i) any breach of any warranties, representations and/or covenants made by you hereunder (to the extent not arising substantially from any breach hereof by Quartrly); and/or (ii) any third party claim arising out of or in relation to App or Site or use thereof in combination with your business platform, including without limitation, any claim that App or Siteviolates, infringes, or misappropriates any proprietary or intellectual property right of any third party, including without limitation, any privacy right of any person, or violates any applicable law.
          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Generals
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Assignment. These terms will inure to the benefit of any successors of the parties.
          <br /><br />
          Content Moderation. Quartrly, Inc. hereby reserves the right, at its sole and final discretion, to review any and all content delivered into App or Site, and use moderators and/or any monitoring technology to flag and remove any user-generated content or other content deemed inappropriate.
          <br /><br />
          Equitable remedies: You hereby acknowledge and agree that if these Terms are not specifically enforced, Quartrly, Inc. will be irreparably damaged, and therefore you agree that Quartrly, Inc. shall be entitled, without bond, other security or proof of damages, to appropriate equitable remedies with respect to your breach of any of these Terms, in addition to any other available remedies.
          <br /><br />
          Force Majeure. Quartrly, Inc. shall not liable for any failure of performance on its obligations as set forth herein, where such failure arises from any cause beyond Quartrly', Inc. reasonable control, including but not limiting to, electronic, power, mechanic or Internet failure.
          <br /><br />
          No Embargo. You hereby represent and warrant that: (i) you are not located in a country that is subject to a U.S.A. government’s embargo, or that has been designated by the U.S.A. government as a “terrorist supporting” country; and (ii) you are not listed on any U.S.A. government list of prohibited or restricted parties or activities.
          <br /><br />
          No Waiver. Failure by Quartrly, Inc. to enforce any rights hereunder shall not be construed as a waiver of any rights with respect to the subject matter hereof.
          <br /><br />
          No Relationship. You and Quartrly, Inc. are independent contractors, and no agency, partnership, joint venture, employee-employer, or franchiser-franchisee relationship is intended or created by these Terms.
          <br /><br />
          Product claims. You acknowledge and agree that Quartrly, Inc., and, not Google Inc. or Apple Inc., is responsible for addressing any claims of you or any third party relating to the App in your use or possession, including, but not limited to: (i) product liability claims; (ii) any maintenance and support services; (iii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iv) claims arising under consumer protection or similar legislation.
          <br /><br />
          Severability. If any provision of these Terms is held unenforceable, then such provision will be modified to reflect the parties' intention. All remaining provisions of these Terms will remain in full force and effect.
          <br /><br />
          Waiver of Class Actions, Non-Individualized Relief. You acknowledge and accept that claims brought against Quartrly, Inc. shall be only on an individual basis and not as a plaintiff or class member in any possible future class or representative action or similar proceeding. Unless otherwise agreed by you and Quartrly, Inc., you may not adjoin or consolidate any claim with more than one person's; and you may not otherwise supervise or take over any form of a class, representative or consolidated proceeding.
          <br /><br />
          Applicable Law. Your use of this App and Site and any cause of action, claim and/or dispute that might arise between the parties hereunder, shall be subject to the laws of the State of Nevada, United States of America, without regard to conflict of law principles.
          <br /><br />
          Forum. You agree that any dispute arising from or relating to these Terms will be heard solely by a court or tribunal of competent jurisdiction in or nearest to the City of Las Vegas, State of Nevada, United States of America. If you bring a dispute in a manner other than in accordance with this section, you agree that we may move to have it dismissed, and that you will be responsible for our reasonable attorney’s fees, court costs, and disbursements in doing so. You agree that the unsuccessful party in any dispute arising from or relating to these Terms will be responsible for the reimbursement of the successful party’s reasonable attorney’s fees, court costs, and disbursements.
          <br /><br />
          Waiver of Jury Trial. The parties herein waive their constitutional and statutory rights to go to court and have a trial in front of a jury, instead electing that all claims and disputes be resolved by a competent judge.
          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Contact
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          If you have any questions or queries about us, the Site, the App, our Services or these Terms, please contact us as indicated in our contact page: www.quartrly.com/contact.
          <br /><br />
          Date of last effective update is August 31, 2021.
          <br /><br />
        </Typography>
      </div>
    </div>
  )
}
function French() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Introduction
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Bienvenue, ce sont les Conditions générales de service (les « Conditions ») de l'application mobile appelée Quartrly (l' Application ) et du site Web situé à l' adresse www.quartrly.com (le « site »), y compris ses -domaines et son mobile - versions optimisées. Le site et l'application fièrement exploités par Quartrly, Inc. (« nous », «ses», «nos», «nous» ). Les présentes conditions régissent les relations entre l'utilisateur et le visiteur ( «vous», «votre», «vous-même ». ) et Quartly, Inc. concernant l'utilisation de l'application et du site Web. Quartrly, Inc. se réserve le droit de modifier, amender, suspendre, résilier, mettre à niveau, mettre à jour ou autrement modifier les Services, le Site et l'App, à tout moment et avec sur avis. Toute modification de ces conditions sera affichée sur l'application et le site, et nous pouvons vous en informer via le site, l'application ou par e-mail. Veuillez vous référer à la dernière date d'entrée en vigueur de la dernière modification que nous avons apportée. Votre utilisation de nos services après la date effective de toute mise à jour - soit par un enregistrement de compte ou une simple utilisation - indique ainsi votre acceptation de celle-ci. .
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Acceptation
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          EN VOUS INSCRIVANT, EN ACCÉDANT, EN NAVIGANT, EN TÉLÉCHARGEANT À PARTIR DE L'UTILISATION DE L'APPLICATION OU DU SITE WEB, AVEC TOUS LES PRODUITS ET LES SERVICES ICI QUI SONT OFFERTS (LE «SERVICE (S)»), VOUS ACCEPTEZ QUE VOUS AVEZ LU ET ACCEPTEZ DE VOUS LIRE COMPLÈTEMENT ET D'ÊTRE COMPLÈTEMENT LIÉ PAR LES PRÉSENTES CONDITIONS D'UTILISATION ET LA POLITIQUE DE CONFIDENTIALITÉ (LA «POLITIQUE»), INCORPORÉE AUX PRÉSENTES PAR RÉFÉRENCE. CES CONDITIONS CONSTRUISENT UN ACCORD ENTIER ENTRE VOUS ET NOUS.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          VOUS DÉCLAREZ QUE VOUS ÊTES D'ÂGE JURIDIQUE (18 ANS), ET VOUS AVEZ LE POUVOIR ET LES COMPÉTENCES POUR ENTRER ET POUR CONFORMER À CES CONDITIONS. SI VOUS N'ÊTES PAS D'ÂGE JURIDIQUE, N'UTILISEZ PAS NOTRE SITE OU APP.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Enregistrement général du compte utilisateur, vérification et sécurité
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Pour utiliser Quartrly, vous devez créer un compte d'utilisateur et fournir les informations demandées. Votre profil d'utilisateur doit inclure une vraie photographie et votre vrai prénom et nom. Vous devrez peut-être fournir et nous autoriser à accéder à la connexion aux médias sociaux demandée, ainsi qu'à l'accès aux informations personnelles telles que les listes d'amis, les e-mails, la galerie d'images, les likes et autres informations. Si vous fermez votre compte de réseau social ou si vous retirez l'autorisation de notre accès, votre compte chez nous peut se fermer.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          En nous accordant l'accès à votre compte de médias sociaux, vous acceptez et comprenez que nous aurons le droit d'accéder à et de stocker certaines informations vous concernant, comme décrit dans les présentes et dans notre politique. Actuellement, la fonction de connexion au compte de médias sociaux ne prend en charge que Facebook. À cette fin, vous autorisez le Quartrly à accéder aux informations de votre compte Facebook et vous acceptez les conditions d'utilisation de Facebook (lien https://www.facebook.com/term s.php ) dans votre utilisation du Quartrly. (lien https://www.facebook.com/terms.php ) lors de votre utilisation de Quartrly.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Vous êtes seul responsable de l'activité qui se produit sur votre compte Quartrly et de la sécurité et de la confidentialité de votre mot de passe, et devez nous informer immédiatement de toute violation ou utilisation non autorisée de votre compte. Vous serez responsable de toute utilisation abusive de votre compte, y compris l'utilisation frauduleuse par un tiers, ou même la divulgation de votre mot de passe à un tiers.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Votre accès à Quartrly n'est offert que pour votre usage personnel, et non pour l'usage ou les avantages d'un tiers que vous pourriez représenter..
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Prestations de service
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Nous fournissons une application où l'utilisateur peut demander à communiquer avec un autre utilisateur enregistré. Quartrly permet une communication en temps réel entre deux utilisateurs au moyen d'une interface d'appel vidéo avec utilisation de la fonction de réservation .
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          L'utilisateur général peut rechercher d'autres utilisateurs afin de demander une réservation d'appel multimédia (chacun, un «quart»). L'utilisateur qui demande un Quartr peut voir un autre profil d'utilisateur, la disponibilité, les frais proposés et d'autres éléments.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Par défaut, l'appel vidéo Quartrs dure 15 minutes. L'utilisateur hébergeant un Quartr (l '«Hôte») doit indiquer la disponibilité par date et heure. Si un utilisateur souhaite réserver un Quartr (le «Participant»), il doit demander un créneau horaire disponible.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Processus de réservation Quartr
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartr Appointment. QuartR r.-v. ent . Le participant peut vérifier la disponibilité future de l'hôte et, une fois la date sélectionnée, l'application affichera les blocs de 15 minutes disponibles pour les quarts . Le compte à rebours démarre lorsque le serveur tente de connecter les deux parties et continue de s'exécuter même si une partie perd sa connexion réseau. .
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Available Now Calls. Disponible maintenant Appels . Le participant peut réserver un Quartr immédiat avec un utilisateur qui a défini sa disponibilité sur Disponible maintenant. Après avoir réservé un Quartr avec une transaction de paiement réussie, l'App correspondra à deux utilisateurs .
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          En règle générale, nous n'examinons, ne modérons ni ne sélectionnons les demandes Quartr, audio, vidéo, éléments d'arrière-plan, propositions et messages texte. Nonobstant ce qui précède, nous nous réservons le droit de le faire, y compris l'enregistrement du contenu de chaque Quartr , à notre seule et dernière discrétion.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Gains des utilisateurs de Quartr
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Chaque utilisateur est libre de définir son taux Quartr, et il aura le droit de le mettre à jour jusqu'à quatre (4) fois par mois. Les paiements sont traités via l'application et nos partenaires de traitement des paiements sélectionnés . Les participants sont facturés au coût Quartr et aux autres frais applicables, avant chaque appel, dans tous les cas. Les fonds versés à l'hôte seront versés sur un compte bloqué et ne seront pas versés à l'autre utilisateur tant que l'appel Quartr n'aura pas été exécuté. Les revenus seront décaissés à l'utilisateur en fonction de notre calendrier de décaissement actuel (généralement hebdomadaire) - les décaissements étant supérieurs ou égaux à 100,00 USD. Nous pouvons, à nos propres changements de MAKE discrétion dans le décaissement sc calendrier de diffusion dus à une cause raisonnable. Les fonds seront ensuite envoyés à l'hôte.          <br />
          <br />
          Sauf indication expresse contraire dans les présentes, tous les frais sont finaux et non remboursables. Si nous détectons un rejet de débit ou si aucun paiement n'est reçu par nous pour quelque raison que ce soit de votre carte ou compte, vous nous paierez rapidement tous les montants qui nous sont dus sur préavis. Tout manquement ou incapacité de notre part à traiter tout paiement en vertu des présentes ne vous dégage pas de vos obligations de paiement envers Quartrly.
          <br />
          <br />
          Des frais d'annulation de 10% seront facturés à l'utilisateur qui annule un rendez-vous Quartr au moins vingt-quatre (24) heures avant la date à laquelle un Quartr doit commencer.
          <br />
          <br />
          Des frais d'annulation de 50% seront facturés à l'utilisateur qui annule un rendez-vous V ideo Call dans les vingt-quatre (24) heures précédant l'heure de rendez-vous du Quartr.
          <br />
          LLes frais d'annulation susmentionnés seront appliqués: (i) aux participants via leur compte de traitement des paiements; et (ii) les hôtes contre leurs gains futurs ou, s'il n'y a pas de revenus sur leur compte, vers leur compte de traitement des paiements.
          <br />
          <br />
          <br />
          Si un utilisateur commence un Quartr et n'est pas en mesure de continuer le Quartr (par exemple, des problèmes de connexion Internet, le smartphone perd la batterie, l'utilisateur ferme l'application par accident), les utilisateurs auront un délai d' une minute pour tenter de rétablir la connexion; après quoi le Quartr sera annulé.
          <br />
          <br />
          Si le participant perd la connexion, il lui sera facturé la totalité des frais de quart. Si l'hôte perd la connexion, le participant ne sera facturé que les frais de Quartr au prorata jusqu'au moment où la connexion a été perdue. Frais de passerelle d'env. 3% n'est remboursable à aucun des utilisateurs, même lorsque l'utilisateur annule son rendez-vous à temps.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Avis de non-responsabilité
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartrly ne fournit pas la communication réelle avec d'autres utilisateurs, uniquement la plate-forme pour les appels vidéo et autres types de communication, et rejette par la présente toute représentation ou garantie concernant les utilisateurs contactés, leur probabilité à des personnes et personnalités similaires, leur texte, audio , des messages de contenu photo et vidéo, et pour les performances réelles, la ressemblance personnelle, les compétences ou la disponibilité des utilisateurs contactés. Les appels vidéo que vous recevez ne visent pas à prétendre à une personne identifiable. Par exemple, si vous recevez un appel ou un message d'une personne portant le nom d'utilisateur «John Smith», cet appel n'est pas garanti pour représenter une personne spécifique, et vous ne pourrez pas déduire l'emplacement, l'adresse, le téléphone ou les informations personnelles réels utilisateur, sauf si l'utilisateur choisit de vous le divulguer .
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Suspension et résiliation de compte
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartrly vous encourage à signaler les violations de nos Conditions. Les utilisateurs qui entreprennent des comportements qui peuvent constituer une violation factuelle - ou même alléguée - des présentes Conditions, y compris, mais sans s'y limiter, l'utilisation de mécanismes automatisés pour effectuer des communications frauduleuses, peuvent faire l'objet d'une suspension / résiliation immédiate du compte, à notre seule et dernière discrétion, sans préavis et sans responsabilité.
          <br />
          <br />
          Nous nous réservons le droit, à notre seule et dernière discrétion, de désactiver, geler, suspendre ou résilier tout compte et / ou tout événement, en cas de violation factuelle ou présumée des présentes conditions. Vous devez nous informer immédiatement de tout changement dans votre admissibilité à utiliser les Services, ou si vous soupçonnez une violation ou une utilisation non autorisée de votre compte .
          <br />
          <br />
          Vous reconnaissez et acceptez que nous pouvons signaler toute activité qui, selon nous, peut violer toute loi aux forces de l'ordre, aux régulateurs ou à d'autres tiers concernés, et que toute violation des dispositions susmentionnées peut entraîner la résiliation immédiate de votre accès à nos Services.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          General Payment Terms
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Vous devez nous fournir des informations de facturation valides et à jour. Sauf indication expresse contraire dans les présentes, tous les achats effectués via Quartrly sont définitifs et non annulables ou non remboursables.
          <br />
          <br />
          Quartrly facture un pourcentage du tarif d'appel Quartr de l'hôte (frais de service), comme indiqué à l'utilisateur lors de la réservation Quartr et des frais pour l'exécution de la transaction de paiement (frais de transaction).
          <br />
          <br />
          Quartrly vous facture des frais de service basés sur votre numéro d'identification d'utilisateur permanent , attribué en fonction de vous lorsque vous créez un compte avec Quartrly. Les tarifs des frais de service sont les suivants:
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th style={{ textAlign: 'start' }}>Numéro d'identification de l'utilisateur (selon la séquence de Quartrly création de compte)</th>
              <th>Frais de service% taux</th>
            </tr>
            <tbody>
              <tr>
                <td>1000 premiers utilisateurs</td>
                <td>7.5%</td>
              </tr>
              <tr>
                <td>1001 - 11 000</td>
                <td>9.5%</td>
              </tr>
              <tr>
                <td>11 001 - 111 000</td>
                <td>11.5%</td>
              </tr>
              <tr>
                <td>111 001 - 1 111 000</td>
                <td>12.5%</td>
              </tr>
              <tr>
                <td>1 111 001 - 11 111 000</td>
                <td>13.5%</td>
              </tr>
              <tr>
                <td>11 111 001 - 111 111 000</td>
                <td>14%</td>
              </tr>
              <tr>
                <td>111 111 001 y más</td>
                <td>14.5%</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Exemple 1: calcul des frais de service
          <br />
          L'utilisateur A qui a téléchargé Quartrly et créé le compte, a reçu le numéro d'identification 1023. L'utilisateur B a téléchargé Quartrly et a créé le compte plus tard, et a reçu le numéro 111 032. Les frais de service seront les suivants.
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th>Numéro d'utilisateur (selon la séquence de Quartrl y Compte créé)</th>
              <th>Frais de service% taux</th>
            </tr>
            <tbody>
              <tr>
                <td>Utilisateur A</td>
                <td>7.5%</td>
              </tr>
              <tr>
                <td>Utilisateur B</td>
                <td>12.5%</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartrly vous facture des frais de transaction standard de 6% par rapport au taux d'appel Quartr égal à 10 $ ou plus. Pour les Quartrs qui ont un tarif d'appel de 9 $ ou moins, Quartrly appliquera un montant fixe comme prévu ci-dessous:
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th>Tarif d'appel Quartr	</th>
              <th>Frais de transaction</th>
            </tr>
            <tbody>
              <tr>
                <td>$1</td>
                <td>$0.34</td>
              </tr>
              <tr>
                <td>$2</td>
                <td>$0.39</td>
              </tr>
              <tr>
                <td>$3</td>
                <td>$0.43</td>
              </tr>
              <tr>
                <td>$4</td>
                <td>$0.48</td>
              </tr>
              <tr>
                <td>$5</td>
                <td>$0.52</td>
              </tr>
              <tr>
                <td>$6</td>
                <td>$0.56</td>
              </tr>
              <tr>
                <td>$7</td>
                <td>$0.61</td>
              </tr>
              <tr>
                <td>$8</td>
                <td>$0.65</td>
              </tr>
              <tr>
                <td>$9</td>
                <td>$0.70</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Exemple 2: calcul des frais de transaction
          <br />
          Si le tarif d'appel de l'utilisateur C est de 2 $, le tarif d'appel de l'utilisateur D est de 5 $ et le tarif d'appel de l'utilisateur E est de 15 $, les frais de transaction seront les suivants::
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th>Quartr tarif d'appel</th>
              <th>Frais de transaction</th>
            </tr>
            <tbody>
              <tr>
                <td>Utilisateur C</td>
                <td>$0.39</td>
              </tr>
              <tr>
                <td>Utilisateur D</td>
                <td>$0.52</td>
              </tr>
              <tr>
                <td>Utilisateur E</td>
                <td>$0.90 ($15 * 6%)</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Par la présente, Quartrly se réserve le droit de modifier, d'ajuster et de changer ces frais de service et de transaction de temps à autre, à sa seule et dernière discrétion. Si nous détectons un rejet de débit, ou si aucun paiement n'est reçu par nous pour une raison quelconque à partir d'une carte de crédit ou d'un compte, vous nous paierez rapidement tous les montants qui nous sont dus sur préavis. Tout manquement ou incapacité de notre part à traiter tout paiement en vertu des présentes ne vous dégage pas de vos obligations de paiement.
          <br />
          <br />
          Vous nous autorisez par la présente à débiter votre carte de crédit ou votre compte de traitement des paiements.
          <br />
          <br />
          Nous utilisons des processeurs de paiement tiers acceptés dans le monde entier pour vous facturer via un compte de paiement lié à votre compte Quartrly. Le traitement des paiements sera soumis aux frais, termes, conditions et politiques de confidentialité de ce processeur de paiement. Quartrly n'est pas responsable de toutes les erreurs, frais et frais de conversion de devises par le processeur de paiement, et vous devriez revoir ses termes et politiques de temps en temps, qui régiront la fourniture de services pour vous.
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Rembourser
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Votre satisfaction avec nos services est l'un de nos principaux objectifs. Pour cette raison, nous accordons un remboursement dans une certaine situation selon cette section. Dans tous les cas de remboursement, nous facturons des frais de remboursement de 0,30 $. Vous devez nous contacter dans les 30 jours suivant la facturation afin de recevoir un remboursement. Aucun remboursement ne sera accordé pour toute transaction datant de plus de 30 jours.
          <br />
          <br />
          Si l'hôte a manqué un appel Quartr, nous rembourserons intégralement le participant.
          <br />
          <br />
          Si le participant a manqué l'appel Quartr, aucun remboursement ne sera accordé.
          <br />
          <br />
          Si l'hôte a manqué une partie de l'appel Quartr, nous effectuons un remboursement au prorata en fonction du temps manqué. Nous vous rembourserons tous les frais comme prévu ci-dessous:
          <br />
          <br />
          Exemple 3: Calcul du remboursement partiel.
          <br />
          <br />
          Si les frais d'hôte sont de 20 $ et les frais de service de 10%, le remboursement sera le suivant:
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>1</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>93.33%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$18.67</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.87</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$1.12</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.19</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>2</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>86.67%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$17.33</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.73</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$1.04</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.17</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>3</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>80%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$16</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.60</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.96</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.16</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>4</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>73.33%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$14.67</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.46</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.88</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.15</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>5</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>66.67%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$13.33</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.33</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.80</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.13</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>6</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>60%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$12</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.20</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.72</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.12</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>7</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>53.33%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$10.67</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.06</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.64</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.11</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>8</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>46.67%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$9.33</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$$0.93</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.56</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.09</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>9</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>40%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$8</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.80</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.48</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.08</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>10</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>33.33%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$6.67</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.66</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.40</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.07</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>11</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>26.67%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$5.33</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.53</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.32</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.05</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>12</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>20%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$4</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.40</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.24</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.04</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>13</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>13.33%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$2.67</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.26</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.16</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.03</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>14</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>6.67%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$1.33</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.13</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.08</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.01</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          REMARQUE: e remboursement est calculé jusqu'aux secondes.
          <br />
          <br />
          Si une demande de remboursement survient en raison de notre incapacité à fournir les services décrits dans les présentes conditions, nous vous rembourserons intégralement.
          <br />
          <br />
          Si vous pensez que nous vous avons facturé par erreur, vous devez nous contacter dans les 30 jours suivant cette facturation. Aucun remboursement ne sera accordé pour une transaction de plus de 30 jours. Nous nous réservons le droit de refuser une demande de remboursement si nous pensons ou soupçonnons raisonnablement (i) que vous essayez d'exploiter injustement les dispositions de cette section; (ii) que vous ne respectez pas les conditions générales d'utilisation ; (iii) que vous utilisez Quartrly de manière frauduleuse ou que votre compte d'utilisateur Quartrly est utilisé frauduleusement par un tiers.
          <br />
          <br />
          Pour tous les remboursements dus à une erreur dans le montant de votre paiement, veuillez envoyer un e-mail à support@quartrly.com en indiquant votre adresse, votre nom complet, votre numéro de contact et le motif de la demande de remboursement. Nous essaierons de traiter votre demande dans les meilleurs délais.
          <br />
          <br />
          Veuillez noter que nous pouvons demander des informations supplémentaires afin de traiter votre demande de remboursement. Veuillez noter que la non- fourniture de ces informations sera un motif de refus de remboursement.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Programme de parrainage d'utilisateurs
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Lors de l'inscription à Quartrly, chaque utilisateur se verra attribuer un code d'utilisateur. Il s'agit d'un code à 6 caractères qui peut être partagé avec d'autres personnes afin que le parrain reçoive un pour cent (1%) des gains nets réels de la référence , tant que les deux utilisateurs possèdent un compte valide auprès de Quartrly.
          <br />
          <br />
          En conséquence, l'utilisateur récepteur peut l'utiliser lors de l'activation de son compte dans Quartrly et, à son tour, recevoir son pourcentage des revenus de l'utilisateur référent. Pour éviter tout doute, chaque code d'utilisateur peut être partagé avec plusieurs utilisateurs, mais chaque utilisateur qui s'inscrit individuellement ne pourra l'utiliser qu'une seule fois lors de son inscription, et pas plus tard.
          <br />
          <br />
          Nous vous informerons si votre application de code a réussi et dans ce cas, vous ferez partie de notre programme de parrainage. Toutes les activités des affiliés doivent être menées de manière professionnelle et appropriée. Vous devrez agir avec bonne foi et intégrité dans vos relations avec vos filleuls et toujours agir dans le meilleur intérêt de vos filleuls. Vous devez éviter la divulgation ou l'utilisation non autorisée de toute information confidentielle qui pourrait arriver à votre connaissance ou à la possession de tiers ou de tiers.
          <br />
          <br />
          Quartrly vous versera les revenus détaillés sur la page de parrainage de votre compte. Quartrly se réserve le droit de ne pas tenir compte des comptes en double ou des comptes nominés que Quartrly considère comme non authentiques, à sa seule discrétion. Quartrly peut vous fournir des relevés accessibles via un système électronique détaillant les revenus et les frais générés par les utilisateurs que vous avez référés, le cas échéant, qui vous ont été générés au cours de chaque mois civil. Les conditions de paiement du parrainage seront soumises à la plateforme d'affiliation tierce de notre choix. Veuillez lire et vous familiariser avec ces termes.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Accès à Quartrly
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Seule une copie de l'application soumise à une licence valide de Quartrly peut être utilisée pour accéder aux Services, ainsi que pour télécharger les mises à jour et les correctifs. Si vous transférez ou vendez votre appareil autorisé à une autre personne, vous devez fermer votre session avec Quartrly et supprimer votre copie de l'application. Vous ne pouvez pas transférer ou céder aucun de vos droits en vertu de ces Conditions.
          <br />
          <br />
          L'application nécessite une connexion Internet pour accéder à tout ou partie de ses fonctionnalités et fonctionnalités, pour authentifier l'application, pour se connecter ou pour effectuer d'autres fonctions.
          <br />
          <br />
          Pour que certaines fonctionnalités de l'Application fonctionnent correctement, il peut vous être demandé d'avoir et de maintenir: (i) un compte valide et actif avec un service de données mobiles sans fil ; (ii) un appareil mobile moderne, rapide et adéquat avec les spécifications requises pour exécuter l'application; et / ou (iii) une carte de crédit ou de débit valide et active ou un compte de plate-forme de traitement des paiements. Si vous ne disposez pas de tels éléments, l'application ou certaines de ses fonctionnalités peuvent ne pas fonctionner, être disponibles ou cesser de fonctionner correctement, en tout ou en partie, et par conséquent, Quartrly ne pourra être tenu responsable de toute réclamation, blessure ou dommage résultant à partir de ou en relation avec celui-ci.
          <br />
          <br />
          Vous reconnaissez et acceptez que vous serez seul responsable de tous les frais, y compris les frais de connexion Internet ou de mobile, que vous encourez lors de l'accès, du téléchargement, de la navigation ou de l'utilisation de l'application.
          <br />
          <br />
          Vous ne pouvez pas interférer avec les mesures de contrôle d'accès ni tenter de désactiver ou de contourner ces fonctions de sécurité. Si vous désactivez ou altérez les mesures de protection technique, l'application ne fonctionnera pas correctement et les présentes conditions prendront fin immédiatement, et Quartrly ne sera plus responsable de toute réclamation, blessure ou dommage résultant de ou en relation avec celle-ci.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Modération de contenu
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Nous encourageons les utilisateurs à signaler les violations de nos conditions. Chaque utilisateur est seul responsable de ses activités et de tout contenu publié, transmis ou autrement rendu disponible via nos Services. .
          <br />
          <br />
          Nous avons le droit, mais pas l'obligation, de surveiller toute activité Quartr et le `` contenu généré par l'utilisateur '' (par exemple, les noms, les photos, les publications, les commentaires, les images, les commentaires, les questions et tout autre contenu) pour en déterminer la conformité et pour modifier, refuser publier ou supprimer tout matériel ou contenu soumis ou affiché sur nos Services que nous jugeons être en violation de nos Conditions, Politique ou ce qui est autrement répréhensible.
          <br />
          <br />
          Vous reconnaissez et acceptez que nous pouvons signaler toute activité qui, selon nous, peut violer toute loi aux forces de l'ordre, aux régulateurs ou à d'autres tiers concernés, et que toute violation des dispositions susmentionnées peut entraîner la résiliation immédiate de votre accès à Quartrly et à nos services. .
          <br />
          <br />
          Nous nous réservons le droit (mais pas l'obligation) de surveiller les différends entre vous et d'autres de nos utilisateurs. Nous nous réservons également le droit (mais pas l'obligation) de supprimer les articles, produits, services et contenus générés par les utilisateurs qui seraient interprétés ou considérés comme offensants ou spam.
          <br />
          <br />
          Vous devez immédiatement répondre à tout avis que vous recevez affirmant que votre contenu généré par l'utilisateur viole les droits d'un tiers, y compris les avis en vertu de la Digital Ital Millennium Copyright Act (`` DMCA ''), et prendre des mesures correctives, qui peuvent inclure, sans s'y limiter, supprimer rapidement tout contenu généré par l'utilisateur.
          <br />
          <br />
          Vous reconnaissez et acceptez également que toute violation des dispositions susmentionnées peut entraîner la résiliation immédiate de votre accès à Quartrly et à nos Services, sans remboursement ni remboursement de notre part. Quartrly peut résilier ou suspendre votre compte et / ou votre adhésion à notre Service, à tout moment, pour quelque raison que ce soit, sans préavis, et à notre seule et dernière discrétion.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Activités interdites
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          En tant qu'utilisateur, vous vous engagez à ne pas entreprendre, motiver ou faciliter l'utilisation ou l'accès à l'application ou au site afin de:
          <br />
          a- Enfreignez ces Conditions ou autorisez, encouragez ou facilitez les autres à le faire.
          <br />
          b- Plagier et / ou enfreindre les droits de propriété intellectuelle ou les droits à la vie privée de tout tiers, y compris tout abus de confiance, droit d'auteur, marque de commerce, brevet, secret commercial, droit moral, droit à la vie privée, droit de publicité, ou toute autre propriété intellectuelle ou droit de propriété .
          <br />
          c- Distribuer, publier ou autrement rendre disponible tout contenu qui: (i) enfreint ou met en danger les droits de propriété intellectuelle de toute personne (par exemple, marque, droits moraux, secret commercial, droit d'auteur, droits accessoires ou autres); (ii) permet tout acte qui pourrait promouvoir ou provoquer la discrimination, le racisme, le préjudice, la diffamation, la haine ou la violence contre un individu ou un groupe; (iii) met en danger les enfants et les mineurs; (iv) est ou permet que des activités illégales ou frauduleuses prennent effet ; (v) constitue ou peut constituer une infraction pénale ou capitale ou enfreint d'une autre manière toute loi applicable; (vi) est ou peut être considéré comme de la nudité, érotique, obscène, menaçant / diffamatoire, plagié, armes à feu, tabac, alcool, marijuana, jeux de hasard, pornog raphic ou matériel analogue; (vii) fournir un soutien ou des ressources d'information, dissimuler et / ou déguiser le caractère, l'emplacement et / ou la source à toute organisation déléguée par le gouvernement des États-Unis en tant qu '«organisation terroriste étrangère» conformément à l'article 219 de la loi sur l'immigration.
          <br />
          d- Recueillir, recevoir, transférer ou diffuser toute information personnellement identifiable de toute personne sans le consentement en bonne et due forme du titulaire du titre.
          <br />
          e- Sollicitez de l'argent ou des biens numériques ou physiques auprès d'autres utilisateurs.
          <br />
          f- Demandez à d'autres utilisateurs de cacher l'identité, la source ou la destination de tout argent ou produit illégalement gagné.
          <br />
          g- Téléchargez des informations d'achat contrefaites, fausses, dupliquées, modifiées ou autrement trompeuses.
          <br />
          h- Utilisez n'importe quel processus automatisé ou manuel pour obtenir, copier, traiter , accéder et / ou utiliser nos Services ou toute partie de ceux-ci afin de capturer des données ou du contenu non autorisés, dans quelque but que ce soit.
          <br />
          i- Activer, entreprendre, programmer ou exécuter tout type de système, programme informatique ou technique afin d'extraire des données, de récupérer, de gratter, d'indexer ou autrement d'extraire des informations non autorisées de l'application ou du site ou de toute partie ou flux de données de ceux-ci. À des fins de clarification, de telles actions incluront l'utilisation de personnes, des applications de recherche / récupération de sites, des "robots" et des "araignées" logicielles et tout outil de collecte et d'extraction de données analogues, quels que soient le type et la quantité d'informations destinées à l'ex traction. .
          <br />
          j- Utilisez l'application ou le site pour diffuser tout type de virus informatiques, vers, défauts, chevaux de Troie ou autres éléments de nature destructrice.
          <br />
          k- Entreprendre toute action qui entraînera ou pourrait entraîner une surcharge déraisonnable sur l'application ou le site et / ou la technologie ou l'infrastructure de ses partenaires et affiliés , ou autrement imposer des exigences de trafic excessives de l'application ou du site.
          <br />
          l- Intercepter ou surveiller l'activité via nos Services sans notre autorisation expresse.
          <br />
          m- Accéder à l'Application ou au Site avec l'intention et / ou afin de créer une application ou un site Web comparable ou similaire ou copier toutes les fonctionnalités, graphiques, idées, images, vidéos, propriété intellectuelle ou fonctions de l'Application ou du Site Web;
          <br />
          n- Sinon, décompilez, décompilez ou extrayez le code propriétaire de l'application ou du site.
          <br />
          <br />
          Vous reconnaissez et acceptez également que toute violation des dispositions susmentionnées peut entraîner la résiliation immédiate de votre accès à Quartrly et à nos services, sans remboursement ni remboursement de notre part. Nous pouvons résilier ou suspendre votrecompte et / ou votre adhésion à notre service, à tout moment, pour quelque raison que ce soit, sans préavis et à notre seule et dernière discrétion.
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Conditions de l'App Store
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Vous reconnaissez et acceptez par la présente que ces Conditions sont exécutées entre Quartrly, Inc. et vous, et non entre Google, Inc. ou Apple, Inc. (ci-après «Apple» et «Google»). Désormais, la licence qui vous est accordée pour l'application est également limitée à une licence non transférable pour utiliser l'application par un produit activé par un appareil tiers autorisé que vous possédez ou contrôlez, et tel que permis par les conditions d'utilisation énoncées dans les conditions applicables. magasin d'applications.
          <br />
          <br />
          Ces Conditions peuvent ne pas prévoir de règles d'utilisation de l'Application moins restrictives que les règles d'utilisation énoncées pour les applications sous licence ou qui sont autrement en conflit avec les conditions d'utilisation de Google ou de l'App Store d'Apple.
          <br />
          <br />
          Quartrly, et non un tel fournisseur de magasin d'applications tiers, sera seul responsable de l' application et de tout son contenu. Nous sommes seuls responsables de l'application, des services et de leur contenu.
          <br />
          <br />
          Dans la mesure où ces conditions prévoient des conditions d'utilisation ou des licences pour l'application qui sont moins restrictives que les conditions d'utilisation énoncées dans l'App Store applicable, ou qui sont autrement en conflit avec leur utilisation respective, le terme plus restrictif prévaudra.
          <br />
          <br />
          Nous sommes seuls responsables de la fourniture de tout service de maintenance et d'assistance concernant l'Application, et ni Apple ni Google n'ont d'obligation de fournir des services de maintenance et d'assistance de celle-ci.
          <br />
          <br />
          En cas de non-conformité de l'application à toute garantie applicable, vous pouvez en informer Apple ou Google, et ils peuvent vous rembourser le prix d'achat de l'application uniquement (le cas échéant); une deuxième, dans la mesure permise par la loi applicable, ni Apple ni Google aura d' autres obligations de garantie en ce qui concerne l'application, et toutes les autres réclamations, pertes, responsabilités, dommages, coûts ou dépenses imputables à un défaut de co Nform à toute garantie sera la responsabilité de Quartrly.
          <br />
          <br />
          Vous reconnaissez et acceptez par la présente qu'Apple et Google (et leurs sociétés affiliées) sont des tiers bénéficiaires des présentes Conditions et que, si vous acceptez les conditions générales de ces Conditions, Apple et Google auront le droit (et seront réputés avoir accepté le droit) de faire valoir ces Conditions à votre encontre en tant que tiers.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          La possession
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Les marques de commerce, droits d'auteur, marques de service, noms commerciaux et autres droits de propriété intellectuelle et avis de propriété affichés sur Quartrly sont la propriété de –ou autrement sont concédés sous licence à Quartrly ou à ses concédants de licence ou sociétés affiliées, qu'ils soient reconnus (ou non), et qui sont protégé par des droits de propriété intellectuelle aux États- Unis d'Amérique et dans d'autres juridictions à travers le monde. Les titulaires de titres respectifs peuvent ou non être affiliés à nous ou à nos affiliés, partenaires et annonceurs. Aucune section des présentes ne doit être interprétée comme une intention de vous accorder un droit ou un intérêt dans Quartrly ou nos services, en tout ou en partie.          <br />
          <br />
          Pour faciliter la compréhension, les `` droits de propriété intellectuelle '' désignent tous les droits de brevet, les droits d'auteur, les droits de travail sur les masques, les droits moraux, les droits de publicité, les marques, les droits de marque et de service , la bonne volonté, les droits de secret commercial et autres les droits de propriété existants ou à venir, ainsi que toutes les demandes en conséquence et les enregistrements, renouvellements et extensions de ceux-ci, en vertu des lois des États-Unis d'Amérique et d'autres juridictions applicables.
          <br />
          <br />
          Vous reconnaissez et acceptez que toute utilisation ou exploitation contrefaisante du contenu protégé par des droits d'auteur dans Quartrly et / ou les Services peut nous causer, à nos affiliés, concédants de licence ou fournisseurs de contenu, un préjudice irréparable, qui ne peut être réparé uniquement par la loi, et donc nos affiliés, les concédants de licence ou les fournisseurs de contenu peuvent demander réparation en cas de violation des présentes conditions, soit en équité, soit par voie d'injonction ou d'une autre réparation équitable.
          <br />
          <br />
          Vous reconnaissez et acceptez par la présente que, même en cas de réclamation d'un tiers selon laquelle l'application enfreint les droits de propriété intellectuelle d'un tiers, Quartrly, et non Google Inc. ou Apple Inc., sera seul responsable de l'enquête, de la défense, du règlement et le rejet de toute réclamation pour atteinte à la propriété intellectuelle.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Durée, résiliation
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Le terme des présentes prend effet à la date qui vient en premier parmi: (i) le premier accès au Site; (ii) votre premier accès ou exécution de notre application; ou (iii) Quartrly commence à vous fournir ses Services.
          <br />
          <br />
          Le terme des présentes prendra automatiquement fin à la date antérieure de: (i) la désactivation, la suspension, le gel ou la suppression de votre compte; (ii) la résiliation ou la révocation de votre accès; (iii) la résiliation par Quartrly des présentes Conditions, à sa seule et dernière discrétion ; (iv) la date de résiliation qui vous est indiquée de temps à autre par Quartrly; ou (v) notre décision de ne plus utiliser le Site ou l'Application, à sa seule et dernière discrétion.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Les licences
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          À partir du moment de l'acceptation des présentes conditions, Quartrly, Inc. vous accorde une licence non exclusive limitée, non sous-licenciable, non transférable, révocable, non perpétuelle limitée, sous réserve des restrictions ci-dessous pour télécharger, accéder et utiliser l'application pour votre fins individuelles. Vous vous engagez à ne pas utiliser l'application à d'autres fins. Sauf indication contraire de Quartrly, Inc., vous n'êtes pas autorisé à reproduire, copier, modifier, distribuer, transmettre, afficher, publier, concéder sous licence, créer des œuvres dérivées de l'application ou de toute partie de celle-ci. Tous les droits non expressément accordés dans ces conditions d'utilisation sont réservés par Quartrly, Inc.
          <br />
          <br />
          Si vous avez téléchargé le Quartrly sur l'App Store d'Apple, vous affirmez et acceptez par la présente que vous n'utiliserez le Quartrly (i) que sur un produit de marque Apple qui exécute iOS (système d'exploitation propriétaire d'Apple) et (ii) comme autorisé par le Règles "énoncées dans les conditions d'utilisation de l'App Store d'Apple.
          <br />
          <br />
          Licence de contenu généré par l'utilisateur . Par la présente, vous accordez à Quartrly un droit illimité, non exclusif et libre de droits, pour tout le monde, le droit et la licence de télécharger, utiliser, reproduire, distribuer, analyser et exploiter tout et tout contenu, textes, données et tous les composants qui en découlent que vous ou vos affiliés, agents, employés ou représentants présentent, téléchargent ou livrent de toute autre manière à Quartrly. Vous déclarez et garantissez à Quartrly que vous avez tous les droits, autorisations ou autrement détenez un titre suffisant pour tout le contenu soumis à Quartrly comme indiqué dans les présentes.
          <br />
          <br />
          Tel qu'utilisé dans les présentes ou dans l'application, `` Open Source '' signifie un logiciel open source fourni dans l'application qui vous est concédé sous licence ou sous licence selon les termes et conditions des accords de licence applicables ou des avis de droit d'auteur inclus dans ce composant Open Source . Ces Conditions ne s'appliquent ni ne régissent l'Open Source accompagnant l'App et Quartrly décline par la présente toute garantie, représentation et responsabilité envers vous ou tout tiers lié à celle-ci.          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Liens vers des tiers
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          De temps en temps, le Site, l'Application et / ou les Services peuvent contenir des hyperliens vers d'autres sites Web. Ces liens sont pour votre convenance personnelle et pour vous fournir des informations supplémentaires qui pourraient vous intéresser. La fourniture de ces liens n'implique aucune approbation de ces sites Web tiers (ou de leurs produits et services), détenus par des tiers. Pour éviter tout doute, les présentes Conditions ne régissent en aucun cas l'utilisation de sites Web tiers, accessibles via des liens tiers.
          <br />
          <br />
          De temps en temps, nous pouvons placer des publicités et des promotions provenant de sources tierces sur le Site, l'Application et / ou les Services. En conséquence, votre participation ou vos engagements à des promotions de tiers autres que Quartrly, ainsi que les termes, conditions, garanties ou représentations associés à ces engagements, sont uniquement entre vous et ce tiers. Quartrly n'est pas responsable de toute perte ou dommage de quelque nature que ce soit résultant de ces transactions ou de la présence d'annonceurs tiers.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Confidentialité de l'utilisateu
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          En nous divulguant des informations, vous acceptez les termes de notre politique, y compris les termes de collecte, de traitement, de stockage et de divulgation de ces informations personnellement identifiables, y compris à nos affiliés, partenaires et clients. Nous vous demanderons votre consentement exprès, y compris pour l'inclusion dans nos newsletters, mises à jour et suivis. Pour plus d'informations, veuillez lire notre politique.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Avis concernant la Digital Millennium Cop yright Act («DMCA»)
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Pour les avis de retrait DMCA et les violations présumées des droits de propriété intellectuelle au sein de Quartrly, vous pouvez nous contacter comme indiqué dans notre section contact. En vertu de la loi fédérale des États-Unis, si vous déformez sciemment du contenu en ligne, vous pouvez faire l'objet de poursuites pénales pour parjure et sanctions civiles, y compris des dommages pécuniaires, des frais de justice et des honoraires d'avocat.
          <br /><br />
          Vous devez nous notifier votre réclamation avec pour sujet: "Takedown Request". Une fois reçue, Q uartrly étudiera et considérera votre réclamation et, si elle croit ou a des raisons de croire qu'un contenu sur Quartrly porte atteinte au droit d'auteur d'un autre, Quartrly peut le supprimer, le désactiver ou autrement arrêter de l'afficher. Veuillez noter que cette procédure est exclusivement destinée à informer Quartrly et ses sociétés affiliées que votre matériel protégé par les droits d'auteur a été violé.          <br /><br />
          Votre réclamation de notification DMCA doit être écrite et doit contenir au moins les informations suivantes: (i) votre signature et votre identification, ou celles que le personnel autorisé à agir en votre nom ou en tant que titulaire du titre; (ii) une description claire et concise du contenu que vous alléguez est une violation du droit d'auteur; (iii) les coordonnées (par exemple, adresse et e-mail); et (iv) une déclaration, sous peine de parjure, indiquant que vous croyez de bonne foi que les informations fournies dans votre réclamation sont véridiques et exactes. Les exigences précédentes sont destinées à respecter les droits et obligations de Quartrly en vertu du DMCA, y compris 17 USC 512 (c), mais ne constituent pas des conseils juridiques.          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Aucune garantie
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Ni Quartrly, Inc., ni ses sociétés affiliées, filiales, dirigeants, employés et agents ne garantissent que Quartrly sera exempt d'erreurs, ininterrompu, sécurisé ou produira des résultats particuliers. Aucun conseil ou information donné par Quartrly, Inc. ou ses employés, sociétés affiliées, sous-traitants et / ou agents ne constitue une garantie. Quartrly n'a pas été entièrement testé dans toutes les situations ou tous les appareils, et Quartrly peut contenir ou contiendra des dysfonctionnements opérationnels, des erreurs, des virus, des bogues, des vers , des chevaux de Troie, des robots et d'autres composants ou défauts nuisibles et destructeurs.
          <br /><br />
          Ni Quartrly, Inc. ni ses sociétés affiliées, concédants de licence, propriétaires, filiales, marques ou annonceurs ne sont des conseillers professionnels dans aucune industrie. Les fonctionnalités et les résultats affichés dans Quartrly peuvent ou varieront, sans préavis ou sans préavis, et vous pouvez recevoir Quartrs, ou non, selon la disponibilité des autres utilisateurs et / ou fonctionnalités de Quartrly, et les résultats dépendront sur une variété de facteurs hors du contrôle ultime de Quartrly Votre utilisation de toute information et / ou matériel sur Quartrly est entièrement à vos propres risques, pour lesquels nous ne serons pas tenus responsables.
          <br /><br />
          Inc. et ses sociétés affiliées, clients, hommes, officiers, concédants de licence et / ou distributeurs, ne font aucune garantie expresse, implicite ou légale, par la loi, la common law, la coutume, l'utilisation de le commerce, la conduite des affaires ou autrement, quelle qu'en soit la nature, y compris les garanties implicites de description, de qualité, d'adéquation à un usage particulier, de fonctionnement, d'intégration, d'adéquation, d'adéquation, de titre, de non-contrefaçon, de non-ingérence dans l'utilisation et / ou la jouissance.        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Déni de responsabilité
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          En aucun cas Quartrly, Inc., ses filiales, clients, agents, dirigeants, concédants de licence, distributeurs et / ou tout tiers autorisé, ne pourra être tenu responsable de tout dommage spécial, indirect, accessoire ou consécutif, y compris des pertes, des coûts ou des dépenses. de toute nature résultant de la possession, de l'accès, de l'utilisation ou du dysfonctionnement de l'application ou du site, y compris, mais sans s'y limiter, la perte de revenus, les bénéfices, les affaires, la perte d'utilisation ou le manque de disponibilité des ressources informatiques, les données perdues ou corrompues, le montant de l'approvisionnement, les économies prévues, le gaspillage des dépenses ou toute autre perte commerciale ou économique; ou pour tout dommage indirect, accessoire, spécial, punitif, aggravé, exemplaire ou consécutif, quel qu'il soit, découlant de ou lié à l'application ou au site, qu'il s'agisse d'un délit (y compris la négligence), d'un contrat, d'une responsabilité stricte ou d'une autre théorie légale ou équitable et si Quartrly, Inc., ses sociétés affiliées, ses clients, ses concédants de licence et / ou ses distributeurs ont été informés ou non de la possibilité de tels dommages. Aux fins de cette limitation de responsabilité, Quartrly, Inc. » les affiliés, concédants de licence et distributeurs sont des tiers bénéficiaires des limitations de responsabilité spécifiées dans les présentes et ils peuvent faire valoir ces conditions à votre encontre.
          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Limitation de responsabilité
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          En aucun cas, la responsabilité de Quartrly ', Inc., ou de ses filiales, clients, concédants de licence et / ou distributeurs pour tous les dommages (sauf tel que requis par la loi applicable) ne dépassera le montant de 50,00 USD (cinquante dollars américains ); et désormais, toute indemnité pour dommages directs et prouvables ne dépassera pas ce montant total. Ces conditions vous accordent des droits légaux spécifiques, et vous pouvez avoir d'autres droits qui peuvent varier d'une juridiction à l'autre. La législation de certains États / pays ne permet pas certaines limitations de responsabilité, et désormais cette limitation de responsabilité s'appliquera dans toute la mesure permise par la loi dans la juridiction applicable. Cette limitation de responsabilité ne sera pas applicable uniquement dans la mesure où toute disposition spécifique de cette limitation de responsabilité est interdite par une loi fédérale, étatique ou municipale, qui ne peut être anticipée.
          <br /><br />
          En tant qu'utilisateur, vous reconnaissez et acceptez par les présentes que Google Inc. et Apple Inc., ainsi que leurs sociétés affiliées et filiales, sont des tiers bénéficiaires des présentes conditions et que, si vous acceptez les termes et conditions des présentes , ils auront le droit (et sera réputé avoir accepté le droit) de faire valoir ces conditions à votre encontre en tant que tiers bénéficiaire.          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Je ndemnification
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Vous devez indemniser, dégager de toute responsabilité et défendre Quartrly, Inc. ses affiliés, clients, agents, dirigeants, concédants de licence, distributeurs et / ou tout représentant autorisé, ainsi que les dirigeants, administrateurs et employés de chacun (conjointement, les « Indemnités Quartrly » ) de et contre toutes les responsabilités, réclamations, causes d'action, poursuites, pertes, dommages, amendes, jugements, règlements et dépenses de tiers (y compris tous les honoraires d'avocat et frais de justice raisonnables) qui pourraient être subis, effectués ou découlant de l'une quelconque des indemnités de Quartrly découlant de ou se rapportant à: (i) toute violation de toute garantie, représentation et / ou engagement contracté par vous en vertu des présentes (dans la mesure où elle ne découle pas substantiellement d'une violation des présentes par Quartrly); et / ou (ii) toute réclamation d' un tiers découlant de ou en relation avec l'application ou le site ou son utilisation en combinaison avec votre plateforme commerciale, y compris, sans limitation, toute réclamation selon laquelle l'application ou le site viole, enfreint ou détourne tout droit de propriété ou intellectuel. prop erty droit de tout tiers, y compris sans s'y limiter, tout droit de la vie privée d'une personne, ou viole toute loi applicable..          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Généraux
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Cession . ffectation . Ces conditions s'appliqueront au bénéfice de tous les successeurs des parties.
          <br /><br />
          Contenu Mod ration . Quartrly, Inc. se réserve par la présente le droit, à sa seule et dernière discrétion, d'examiner tout et tout le contenu livré dans l'application ou le site, et d'utiliser des modérateurs et / ou toute technologie de surveillance pour signaler et supprimer tout contenu généré par l'utilisateur ou tout autre contenu jugé inapproprié.          <br /><br />
          Recours en equity : Vous reconnaissez et acceptez par la présente que si ces Conditions ne sont pas spécifiquement appliquées, Quartrly, Inc. sera irrémédiablement endommagé, et par conséquent vous acceptez que Quartrly, Inc. ait droit, sans caution, autre garantie ou preuve de dommages, à des recours équitables appropriés en ce qui concerne votre violation de l'une des présentes conditions, en plus de tout autre recours disponible.
          <br /><br />
          Force Majeure . Quartrly, Inc. ne sera pas responsable de tout manquement à l'exécution de ses obligations énoncées dans les présentes, lorsque ce manquement résulte d'une cause indépendante de Quartrly ', Inc., un contrôle raisonnable, y compris, mais sans s'y limiter, l'électronique, l'alimentation, le mécanicien ou le réseau Internet. échec.<br /><br />
          Pas d'embargo . Vous déclarez et garantissez par la présente que: (i) vous n'êtes pas situé dans un pays soumis à un embargo du gouvernement américain ou qui a été désigné par le gouvernement américain comme un pays «soutenant le terrorisme»; et (ii) vous ne figurez sur aucune liste gouvernementale américaine de parties ou d'activités interdites ou restreintes.
          <br /><br />
          Aucune renonciation . . L'incapacité de Quartrly, Inc. à faire valoir tout droit en vertu des présentes ne doit pas être interprétée comme une renonciation à tout droit concernant l'objet des présentes
          <br /><br />
          Pas de relation . Quartrly, Inc. et vous- même êtes des entrepreneurs indépendants. Aucune relation d'agence , de partenariat, d'entreprise commune, d'employé-employeur ou de franchiseur-franchisé n'est prévue ou créée par les présentes conditions.
          <br /><br />
          Revendications de produit . de produit . Vous reconnaissez et acceptez que Quartrly, Inc., et non Google Inc. ou Apple Inc., est responsable de répondre à toute réclamation de vous ou de tout tiers concernant l'Application en votre possession ou en votre possession, y compris, mais sans s'y limiter: (i) réclamations en responsabilité du fait des produits; (ii) tout service de maintenance et d'assistance; (iii) toute réclamation selon laquelle l'Application ne se conforme pas à toute exigence légale ou réglementaire applicable; et (iv) les réclamations découlant de la protection des consommateurs ou d'une législation similaire.          <br /><br />
          Divisibilité. Si une disposition de ces Conditions est jugée inapplicable, alors cette disposition sera modifiée pour refléter l' intention des parties . Toutes les autres dispositions des présentes conditions resteront en vigueur et de plein effet.
          <br /><br />
          Renonciation aux recours collectifs, réparation non individualisée . Vous reconnaissez et acceptez que les réclamations déposées contre Quartrly, Inc. ne le seront qu'à titre individuel et non en tant que demandeur ou membre du groupe dans le cadre d'une éventuelle action collective ou représentative future ou d'une procédure similaire. Sauf accord contraire entre vous et Quartrly, Inc., vous ne pouvez pas joindre ou consolider une réclamation avec plus d'une personne; et vous ne pouvez pas non plus superviser ou reprendre une quelconque forme de procédure collective, représentative ou consolidée.
          <br /><br />
          Loi applicable . Votre utilisation de cette application et de ce site et de toute cause d'action, réclamation et / ou litige qui pourrait survenir entre les parties aux présentes, sera soumise aux lois de l'État du Nevada, États-Unis d'Amérique, sans égard aux principes de conflit de lois. .
          <br /><br />
          Forum. Vous acceptez que tout litige découlant de ou se rapportant à ces Conditions sera entendu uniquement par une cour ou un tribunal compétent dans ou au plus tôt à la ville de Las Vegas, État du Nevada, États-Unis d'Amérique. Si vous soumettez un différend d'une manière autre que celle prévue dans la présente section, vous acceptez que nous puissions demander son rejet et que vous serez responsable de nos honoraires d'avocat, frais de justice et débours raisonnables pour ce faire. Vous acceptez que la partie qui succombe dans tout litige découlant de ou se rapportant aux présentes Conditions sera responsable du remboursement des honoraires d'avocat, des frais de justice et des débours raisonnables de la partie gagnante
          <br /><br />
          Renonciation au procès devant jury . Les parties aux présentes renoncent à leurs droits constitutionnels et statutaires à aller en justice et à avoir un procès devant un jury, choisissant plutôt que toutes les réclamations et litiges soient résolus par un juge compétent.          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Contact
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Si vous avez des questions ou des questions sur nous, le site, l'application, nos services ou ces conditions, veuillez nous contacter comme indiqué sur notre page de contact: www.quartrly.com / contact.
          <br /><br />
          La dernière mise à jour effective est le 31 août 2021.
          <br /><br />
        </Typography>
      </div>
    </div>
  )
}
function Spanish() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Introducción
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Bienvenido, estos son los Términos y Condiciones del Servicio (los " Términos ") para la aplicación móvil conocida como Quartrly (la " Aplicación ",) y el sitio web ubicado en www.quartrly.com (el " Sitio "), incluido su sub -dominios y sus móviles - versiones optimizadas. El Sitio y la Aplicación operada con orgullo por Quartrly, Inc.(" nosotros ", "su", "nuestro", "nosotros") Estos Términos rigen las relaciones entre el usuario o visitante ( "usted", "su", "usted mismo " ) y Quartly, Inc. con respecto al uso de la aplicación y el sitio web. Quartrly, Inc. se reserva el derecho de modificar, enmendar, suspender, cancelar, actualizar, actualizar o modificar los Servicios, el Sitio y la Aplicación, en cualquier momento y sin previo aviso. Cualquier cambio a estos Términos se mostrará en la Aplicación y el Sitio, y podemos notificarle a través del Sitio, la Aplicación o por correo electrónico. Consulte la última fecha de vigencia en la que nosotros hicimos los últimos cambios. El uso de nuestros servicios después de la fecha de entrada en vigencia de cualquier actualización, ya sea mediante el registro de una cuenta o un uso simple, indica su aceptación de los mismos.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Aceptación
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          AL REGISTRARSE, ACCEDER, NAVEGAR, DESCARGAR O UTILIZAR LA APLICACIÓN O EL SITIO WEB, JUNTO CON CUALQUIER PRODUCTO Y SERVICIO QUE SE OFRECEN AQUÍ (EL "SERVICIO (S)") ACEPTA QUE HA LEÍDO Y ACEPTO COMPLETAMENTE CUMPLIDO CON ESTA CONDICIONES DE SERVICIO Y POLÍTICA DE PRIVACIDAD (LA "POLÍTICA"), INCORPORADAS AQUÍ POR REFERENCIA. ESTOS TÉRMINOS CONSTRUYEN TODO ACUERDO ENTRE USTED Y NOSOTROS.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          USTED ASUME QUE TIENE EDAD LEGAL (1 8 AÑOS DE EDAD), Y TIENE EL PODER Y COMPETENTE PARA ENTRAR EN Y PARA CUMPLIR CON ESTOS TÉRMINOS. SI NO TIENE EDAD LEGAL, NO USE NUESTRO SITIO O APLICACIÓN.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Registro de cuenta de usuario general, verificación y seguridad
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Para usar Quartrly, debe crear una cuenta de un usuario y proporcionar la información solicitada. Su perfil de usuario debe incluir una fotografía real y su nombre y apellido reales. Es posible que deba proporcionarnos y autorizarnos a acceder al inicio de sesión solicitado en las redes sociales, junto con el acceso a información personal como listas de amigos, correo electrónico, galería de imágenes, me gusta y otra información. Si cierra su cuenta de redes sociales, o si no autoriza nuestro acceso, su cuenta con nosotros puede cerrarse.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Al concedernos acceso a su cuenta de redes sociales , usted acepta y comprende que tendremos derecho a acceder y almacenar cierta información sobre usted como se describe en este documento y en nuestra Política. Actualmente, la función de inicio de sesión de la cuenta de redes sociales solo admite Facebook. Para este propósito, permite que Quartrly acceda a la información de su cuenta de Facebook y acepta los Términos de servicio de Facebook (enlace https://www.facebook.com/terms.php ) en su uso de Quartrly.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Usted es el único responsable de la actividad que se produce en su cuenta de Quartrly y de mantener su contraseña segura y confidencial, y debe notificarnos de inmediato cualquier incumplimiento o uso no autorizado de su cuenta. Usted será responsable de cualquier mal uso de su cuenta , incluido el uso fraudulento por parte de un tercero, o incluso la divulgación de su contraseña a un tercero.        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Su acceso a Quartrly solo se ofrece para su uso individual, y no para el uso o beneficio de terceros que pueda representar.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Servicios
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Proporcionamos una aplicación en la que los usuarios pueden solicitar comunicarse con otro usuario registrado. Quartrly permite la comunicación en tiempo real entre el usuario puede por medio de una interfaz de llamada de vídeo con el uso d e Funcionalidad de reserva .
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          El usuario general puede buscar a otros rs de uso para solicitar la reserva de llamadas multimedia (cada uno, un "Quartr"). El usuario que solicita un Quartr puede ver otro perfil de usuario, disponibilidad, tarifa propuesta y otros artículos.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          De forma predeterminada, la videollamada Quartrs dura 15 minutos. E1 usuarios que aloja un Quartr (el "Host") debe indicar la disponibilidad por fechas y horas. Si un usuario desea reservar un Quartr (el "Asistente"), debe solicitar un intervalo de tiempo que esté disponible.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Proceso de reserva quartr
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartr bram ent Los asistentes pueden revisar la disponibilidad futura de Host, y una vez que se seleccione una fecha, la aplicación mostrará los bloques de 15 minutos que están disponibles para Quartr s. El temporizador de cuenta regresiva comienza cuando el servidor intenta conectar a ambas partes y continúa ejecutándose incluso si una parte pierde su conexión de red.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Disponible ahora llamadas . Los asistentes pueden reservar un Quartr inmediato con un usuario que haya establecido su Disponibilidad en Disponible ahora. Después de reservar un Quartr con una transacción de pago exitosa, la aplicación coincidirá con dos usuarios.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Como regla general, no revisamos, moderamos ni seleccionamos las solicitudes de Quartr, audio, video, elementos de fondo, propuestas y mensajes de texto. No obstante lo anterior, nos reservamos el derecho de hacerlo, incluida la grabación del contenido de cada Quartr, a nuestra exclusiva y última discreción.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Ingresos del usuario de Quartr
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Cada usuario es libre de definir su tasa Quartr y tendrá derecho a actualizarla hasta cuatro (4) veces por mes. Los pagos se procesan a través de la aplicación y nuestros socios de procesamiento de pagos seleccionados . Los asistentes pagan el costo del trimestre y otras tarifas aplicables, antes de iniciar cada llamada, en cualquier caso. Los fondos pagados al Anfitrión irán a una cuenta de depósito en garantía y no se desembolsarán al otro usuario hasta que la llamada de Quartr haya finalizado . Las ganancias se desembolsarán al usuario en función de nuestro cronograma de desembolsos actual (generalmente semanal), con desembolsos superiores o iguales a $ 100.00 dólares estadounidenses. Nosotros podemos, en nuestros propios cambios discreción maquillaje en el desembolso sc gramación de debe a causa razonable. Los fondos se enviarán al anfitrión.
          <br />
          <br />
          A excepción de lo expresamente establecido en este documento, todos los cargos son finales y no reembolsables. Si detectamos algún contracargo o si no recibimos algún pago de su tarjeta o cuenta por algún motivo , nos pagará de inmediato cualquier monto que se nos deba mediante notificación. Cualquier falla o incapacidad por nuestra parte para procesar cualquier pago a continuación no lo libera de sus obligaciones de pago con Quartrly.
          <br />
          <br />
          Se aplicará un cargo por cancelación del 10% al usuario que cancele una cita trimestral al menos veinticuatro (24) horas antes de la fecha en que está programado el comienzo de un trimestre.
          <br />
          <br />
          Se aplicará un cargo por cancelación del 50% al usuario que cancele una Cita de Video Call dentro de las veinticuatro (24) horas anteriores a la hora de la cita del Quartr.
          <br />
          <br />
          Los cargos de cancelación antes mencionados se aplicarán a: (i) los asistentes a través de su cuenta de procesamiento de pagos; y (ii) Hosts contra sus ganancias futuras o, si no hay ganancias en su cuenta, a su cuenta de procesamiento de pagos.
          <br />
          <br />
          Si un usuario comienza un Quartr y no puede continuar el Quartr (por ejemplo, problemas de conexión a Internet, el teléfono inteligente pierde la batería, el usuario cierra la aplicación por accidente), los usuarios tendrán un plazo de un minuto para intentar restablecer la conexión; después de lo cual se cancelará el Quartr.
          <br />
          <br />
          Si el asistente pierde la conexión, se le cobrará la tarifa completa de Quartr. Si el Anfitrión pierde la conexión, el Asistente solo cobrará la tarifa prorrateada de Quartr hasta el momento en que se perdió la conexión. Tarifas de pasarela de aprox. El 3% no es reembolsable para ninguno de los usuarios, incluso cuando el usuario cancela su cita a tiempo.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Descargo de responsabilidad del servicio
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartrly no proporciona la comunicación real con otros usuarios, solo la plataforma para videollamadas y otros tipos de comunicación, y por la presente renuncia a toda representación o garantía sobre los usuarios contactados, su probabilidad para personas y personalidades similares, su texto, audio , mensajes de contenido de foto y video, y para el desempeño real, imagen personal, habilidad o disponibilidad de los usuarios contactados. Las videollamadas que reciba no pretenden ser personas identificables. Por ejemplo, si recibe una llamada o mensaje de alguien con el nombre de usuario "John Smith", no se garantiza que dicha llamada represente a ninguna persona específica, y no podrá inferir la ubicación, dirección, teléfono o información personal real de usuario, a menos que el usuario elija divulgarlo .
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Suspensión de la cuenta y terminación
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartrly lo alienta a informar las infracciones de nuestros Términos. Los usuarios que realicen conductas que puedan constituir un incumplimiento real, o incluso alegado, de estos Términos, que incluyen, entre otros, el uso de mecanismos automáticos para realizar comunicaciones fraudulentas, pueden quedar sujetos a la suspensión / finalización inmediata de la cuenta, a nuestro exclusivo y último criterio, sin previo aviso y sin responsabilidad.
          <br />
          <br />
          Nos reservamos el derecho, a nuestra única y última discreción, de desactivar, congelar, suspender o cancelar cualquier cuenta y / o evento, en caso de incumplimiento de estos Términos. Debe notificarnos de inmediato cualquier cambio en su elegibilidad para usar los Servicios, o si sospecha de una violación o uso no autorizado de su cuenta.
          <br />
          <br />
          Usted reconoce y acepta que podemos informar cualquier actividad que creemos que puede violar cualquier ley a las fuerzas del orden, a los reguladores u otros terceros relevantes, y que cualquier violación de las disposiciones antes mencionadas puede resultar en la terminación inmediata de su acceso a nuestros Servicios.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Condiciones generales de pago
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Usted debe proporcionar una información de facturación actual nd válida. Excepto como se establece expresamente en este documento, todas las compras realizadas a través de Quartrly son definitivas y no cancelables o no reembolsables.
          <br />
          <br />
          Cobra de manera trimestral un porcentaje de la tarifa de llamadas de Quartr del host (tarifa de servicio), según se indica para usar r durante la reserva de Quartr y la tarifa por el cumplimiento de la transacción de pago (tarifa de transacción).
          <br />
          <br />
          Le cobra una Cuota de Servicio en función de su número de identificación de usuario permanente, que se le asigna cuando crea una cuenta con Quartrly. Las tarifas de las tarifas de servicio son las siguientes:
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th style={{ textAlign: 'start' }}>Número de identificación del usuario (según secuencia de Quartrly creación de cuenta)	</th>
              <th>Tarifa de servicio% tasas
              </th>
            </tr>
            <tbody>
              <tr>
                <td>Primeros 1000 usuarios	</td>
                <td>7.5%</td>
              </tr>
              <tr>
                <td>1001 - 11 000</td>
                <td>9.5%</td>
              </tr>
              <tr>
                <td>11 001 - 111 000</td>
                <td>11.5%</td>
              </tr>
              <tr>
                <td>111 001 - 1 111 000</td>
                <td>12.5%</td>
              </tr>
              <tr>
                <td>1 111 001 - 11 111 000</td>
                <td>13.5%</td>
              </tr>
              <tr>
                <td>11 111 001 - 111 111 000</td>
                <td>14%</td>
              </tr>
              <tr>
                <td>111 111 001 y más</td>
                <td>14.5%</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Ejemplo 1: Cálculo de la tarifa de servicio
          <br />
          El usuario A que descargó Quartrly y creó la cuenta, recibió el número de identificación 1023. El usuario B descargó Quartrly y creó la cuenta más tarde, y recibió el número 111 032. La tarifa de servicio será la siguiente.
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th>Número de usuario (según la secuencia de Quartrl y cuenta creada)	</th>
              <th>Tarifa de servicio% tasas
              </th>
            </tr>
            <tbody>
              <tr>
                <td>Usuario A</td>
                <td>7.5%</td>
              </tr>
              <tr>
                <td>Usuario B</td>
                <td>12.5%</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Cobrarle un cargo de transacción estándar del 6% de la tarifa de llamadas de Quartr igual a $ 10 o más. Para los Quartrs que tienen una tasa de llamadas de $ 9 o menos, Quartrly aplicará un monto fijo según lo programado a continuación:
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th>Tasa de llamadas de cuarzo	</th>
              <th>Tarifa de transacción
              </th>
            </tr>
            <tbody>
              <tr>
                <td>$1</td>
                <td>$0.34</td>
              </tr>
              <tr>
                <td>$2</td>
                <td>$0.39</td>
              </tr>
              <tr>
                <td>$3</td>
                <td>$0.43</td>
              </tr>
              <tr>
                <td>$4</td>
                <td>$0.48</td>
              </tr>
              <tr>
                <td>$5</td>
                <td>$0.52</td>
              </tr>
              <tr>
                <td>$6</td>
                <td>$0.56</td>
              </tr>
              <tr>
                <td>$7</td>
                <td>$0.61</td>
              </tr>
              <tr>
                <td>$8</td>
                <td>$0.65</td>
              </tr>
              <tr>
                <td>$9</td>
                <td>$0.70</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Ejemplo 2: Cálculo de la tarifa de transacción
          <br />
          Si la tarifa de llamada del usuario C es de $ 2, la tarifa de las llamada del usuario D es de $ 5, y la tarifa de las llamada del usuario E es de $ 15, la tarifa de transacción será la siguiente:
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tr>
              <th>Tasa de llamadas de cuarzo	</th>
              <th>Tarifa de transacción
              </th>
            </tr>
            <tbody>
              <tr>
                <td>Usuario C</td>
                <td>$0.39</td>
              </tr>
              <tr>
                <td>Usuario D</td>
                <td>$0.52</td>
              </tr>
              <tr>
                <td>Usuario E</td>
                <td>$0.90 ($15 * 6%)</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Quartrly se reserva el derecho de modificar, ajustar y cambiar la Tarifa de servicio y la Tarifa de transacción de vez en cuando, a su exclusivo y último criterio. Si detectamos cualquier devolución de cargo, o si no recibimos ningún pago de nuestra tarjeta de crédito o cuentacorriente, usted nos pagará de inmediato cualquier y todos los montos que nos deban en el momento de la notificación. Cualquier falla o incapacidad de nuestra parte para procesar cualquier pago a continuación no lo exime de sus obligaciones de pago.
          <br />
          <br />
          Usted nos autoriza a cargar en su tarjeta de crédito o en su cuenta de procesamiento de pagos .
          <br />
          <br />
          Utilizamos procesadores de pago de terceros aceptados en todo el mundo para facturarle a través de una cuenta de pago vinculada a su cuenta con Quartrly . El procesamiento de los pagos estará sujeto a las tarifas, términos, condiciones y políticas de privacidad de dicho procesador de pagos. Quartrly no es responsable de ninguno y todos los errores, aranceles y tasas de conversión de moneda por parte del procesador de pagos, y usted debe revisar sus términos y políticas de vez en cuando, que regirán la prestación de servicios a usted.
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Refund
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Su satisfacción con nuestros servicios es uno de nuestros principales objetivos. Debido a esto, estamos proporcionando un reembolso en una situación determinada de acuerdo con esta sección. En cualquier caso de reembolso, cobramos una tarifa de reembolso de $ 0,30.Debe ponerse en contacto con nosotros dentro de los 30 días posteriores al cargo para recibir un reembolso. No se otorgarán reembolsos por ninguna transacción que tenga más de 30 días de antigüedad.
          <br />
          <br />
          Si el Host perdió una llamada de Quartr, le proporcionaremos un reembolso completo al Asistente.
          <br />
          <br />
          Si el Host perdió una llamada de Quartr, le proporcionaremos un reembolso completo al Asistente.
          <br />
          <br />
          Si el Anfitrión perdió parte de la llamada de Quartr, hacemos un reembolso prorrateado en función del tiempo perdido. Le reembolsaremos todas las tarifas según lo programado a continuación:
          <br />
          <br />
          Ejemplo 3: Cálculo de reembolso parcial.
          <br />
          <br />
          Si la tarifa de Host es de $ 20 y la tarifa de Servicio es del 10%, el reembolso será el siguiente:
          <br />
          <br />
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>1</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>93.33%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$18.67</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.87</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$1.12</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.19</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>2</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>86.67%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$17.33</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.73</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$1.04</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.17</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>3</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>80%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$16</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.60</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.96</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.16</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>4</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>73.33%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$14.67</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.46</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.88</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.15</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>5</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>66.67%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$13.33</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.33</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.80</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.13</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>6</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>60%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$12</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.20</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.72</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.12</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>7</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>53.33%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$10.67</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$1.06</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.64</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.11</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>8</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>46.67%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$9.33</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$$0.93</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.56</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.09</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>9</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>40%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$8</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.80</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.48</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.08</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>10</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>33.33%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$6.67</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.66</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.40</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.07</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>11</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>26.67%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$5.33</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.53</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.32</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.05</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>12</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>20%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$4</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.40</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.24</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.04</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>13</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>13.33%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$2.67</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.26</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.16</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.03</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          <table>
            <tbody>
              <tr>
                <tr>Longitud del cuarto (minutos)	</tr>
                <td>14</td>
              </tr>
              <tr>
                <tr>Reembolso %</tr>
                <td>6.67%</td>
              </tr>
              <tr>
                <tr>Tarifa de cuarto de llamada ($ 20) reembolsada</tr>
                <td>$1.33</td>
              </tr>
              <tr>
                <tr>Tarifa de servicio (10%) reembolsada		</tr>
                <td>$0.13</td>
              </tr>
              <tr>
                <tr>Tarifa de transacción (6%) reembolsada	</tr>
                <td>$0.08</td>
              </tr>
              <tr>
                <tr>Tarifa de referencia (1%)
                </tr>
                <td>$0.01</td>
              </tr>
            </tbody>
          </table>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          NOTA: El reembolso se calcula hasta los segundos.
          <br />
          <br />
          Si surge una solicitud de reembolso debido a que no hemos ofrecido los Servicios descritos en estos Términos, le proporcionaremos un reembolso completo.
          <br />
          <br />
          Si cree que le hemos cobrado por error, debe comunicarse con nosotros dentro de los 30 días de dicho cargo. No se otorgarán reembolsos por una transacción que tenga más de 30 días de antigüedad. Nos reservamos el derecho de rechazar una solicitud de reembolso si razonablemente creemos o sospechamos (i) que está tratando de explotar injustamente las disposiciones de esta sección; (ii) que está incumpliendo los términos de los Términos de servicio; (iii) que está utilizando Quartrly de manera fraudulenta o que su cuenta de usuario de Quartrly está siendo utilizada por un tercero de manera fraudulenta.
          <br />
          <br />
          Para todos los reembolsos debidos a un error en el monto de su pago, envíe un correo electrónico a support@quartrly.com indicando su dirección, nombre completo, número de contacto y motivo para solicitar un reembolso. Wewil Trato de hacer frente a su solicitud a la mayor brevedad posible.
          <br />
          <br />
          Tenga en cuenta que podemos solicitar información adicional para procesar su solicitud de reembolso. Tenga en cuenta que la no presentación de dicha información será motivo de denegación de reembolso.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Programa de referencia del usuario
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Al registrarse en Quartrly, a cada usuario se le asignará un código de nombre de usuario. Este es un código de 6 caracteres que se puede compartir con otras personas para que el remitente reciba el uno por ciento (1%) de las ganancias netas, reales de la referencia , siempre y cuando ambos usuarios tengan una cuenta válida con Quartrly.
          <br />
          <br />
          En consecuencia, el usuario receptor puede usarlo al activar su cuenta en Quartrly y, a su vez, recibir su porcentaje de las ganancias del usuario referente. Para evitar dudas, cada código de usuario puede compartirse con múltiples usuarios, pero cada usuario que se registre individualmente solo podrá usarlo una vez al registrarse, y no más tarde.
          <br />
          <br />
          Le notificaremos si su solicitud de código ha sido exitosa y, en tal caso, se convertirá en parte de nuestro programa de referencia. Todas las actividades de los afiliados deben llevarse a cabo de una manera profesional y adecuada. Se espera que actúe con buena fe e integridad en sus relaciones con sus referidos, y que actúe siempre en el mejor interés de sus referidos . Evitará la divulgación o el uso no autorizado de cualquier información confidencial que pueda llegar a su conocimiento o posesión a terceros o terceros.
          <br />
          <br />
          Quartrly le pagará los ingresos detallados en la página de referencia de su cuenta. Quart rly se reserva el derecho de ignorar las cuentas duplicadas o las cuentas nominadas que Quartrly considere no auténticas, a su entera discreción. Quartrly puede proporcionarle declaraciones accesibles a través de un sistema electrónico que detalla los ingresos y cargos generados por los usuarios que ha referido, si corresponde, que le han acumulado a lo largo de cada mes calendario. Los términos de pago de la referencia estarán sujetos a la plataforma de afiliación de terceros de nuestra elección. Por favor lea y familiarícese con tales términos.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Acceso a trimestral
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Solo se puede usar una copia de la aplicación sujeta a una licencia válida de Quartrly para acceder a los Servicios y para descargar actualizaciones y parches. Si transfiere o vende su dispositivo autorizado a otra persona, debe cerrar su sesión con Quartrly y eliminar su copia de la aplicación. No puede transferir ni ceder ninguno de sus derechos según estos Términos.
          <br />
          <br />
          La aplicación requiere una conexión a Internet para acceder a algunas o parte de sus características y funcionalidades, para autenticar la aplicación, para iniciar sesión o para realizar otras funciones.
          <br />
          <br />
          Para que ciertas funciones de la aplicación funcionen correctamente, es posible que deba tener y mantener una: (i) cuenta válida y activa con un servicio de datos móviles inalámbricos ; (ii) dispositivo móvil moderno, rápido y adecuado con las especificaciones requeridas para ejecutar la aplicación; y / o (iii) tarjeta de crédito o débito válida y activa o cuenta de plataforma de procesamiento de pagos. Si no tiene dichos artículos, entonces la Aplicación o ciertas características de la misma pueden no funcionar, estar disponibles o dejar de funcionar correctamente, ya sea en su totalidad o en parte, y por lo tanto, Quartrly no será responsable de ningún reclamo, lesión o daño que surja fuera o en conexión con el mismo.
          <br />
          <br />
          Y ou reconoce y acepta que usted será el único responsable de cualquier tarifa, incluyendo conexión a Internet o los honorarios móviles, en que incurra al acceder, descargar, navegar o utilizar la aplicación.
          <br />
          <br />
          No puede interferir con ninguna medida de control de acceso ni intentar deshabilitar o eludir dichas funciones de seguridad. Si deshabilita o manipula las medidas de protección técnica, la aplicación no funcionará correctamente y estos Términos terminarán de inmediato, y en adelante Quartrly no será responsable de ninguna reclamación, lesión o daño que surja de o en conexión con ella.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Moderación de contenido
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Alentamos a los usuarios a informar violaciones de nuestros Términos. Cada usuario es el único responsable de sus actas de acción y del contenido publicado, transmitido o puesto a disposición a través de nuestros Servicios.
          <br />
          <br />
          Es nuestro derecho, pero no la obligación, monitorear cualquier actividad de Quartr y el "contenido generado por el usuario" (por ejemplo, nombres, fotos, comentarios, imágenes, comentarios, preguntas y otro contenido) para determinar el cumplimiento de los mismos, y para editar, rechazar para publicar o eliminar cualquier material o contenido enviado o publicado en nuestros Servicios que consideremos que infringe nuestros Términos, Políticas o que de otro modo es objetable.
          <br />
          <br />
          Usted reconoce y acepta que podemos informar cualquier actividad que creemos que puede violar cualquier ley a las fuerzas del orden, a los reguladores u otros terceros relevantes, y que cualquier violación de las disposiciones mencionadas puede resultar en la terminación inmediata de su acceso a Quartrly y nuestros Servicios.
          <br />
          <br />
          Nos reservamos el derecho (pero no la obligación) de monitorear las disputas entre usted y otros de nuestros usuarios. También nos reservamos el derecho (pero no la obligación) de eliminar artículos, productos, servicios y usuarios - contenido generado que Woul d ser interpretado o considerado ofensivo o spam.
          <br />
          <br />
          También reconoce y acepta que cualquier violación de las disposiciones antes mencionadas puede resultar en la terminación inmediata de su acceso a Quartrly y nuestros Servicios, sin reembolso ni reembolso por nuestra parte. Quartrly puede cancelar o suspender su cuenta y / o membresía para nuestro Servicio, en cualquier momento, por cualquier motivo, sin previo aviso, y a nuestra única y última discreción.
          <br />
          <br />
          También reconoce y acepta que cualquier violación de las disposiciones antes mencionadas puede resultar en la terminación inmediata de su acceso a Quartrly y nuestros Servicios, sin reembolso ni reembolso por nuestra parte. Quartrly puede cancelar o suspender su cuenta y / o membresía para nuestro Servicio, en cualquier momento, por cualquier motivo, sin previo aviso, y a nuestra única y última discreción.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Actividades prohibidas
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Como nuestro usuario, usted acepta no emprender, motivar o facilitar el uso o acceso de la Aplicación o el Sitio para:
          <br />
          a- Infrinja estos Términos, o permita, aliente o facilite a otros a hacerlo.
          <br />
          b- Plagiar y / o infringir los derechos de propiedad intelectual o los derechos de privacidad de cualquier tercero, incluido cualquier abuso de confianza, derechos de autor, marca registrada, patente, secreto comercial, derecho moral, derecho de privacidad, derecho de publicidad o cualquier otra propiedad intelectual o derecho de propiedad .
          <br />
          c- Distribuir, publicar o poner a disposición cualquier contenido que: (i) infrinja o ponga en peligro los derechos de propiedad intelectual de cualquier persona (por ejemplo, marca registrada, derechos morales, secretos comerciales, derechos de autor, derechos accesorios u otros); (ii) permite cualquier acto que pueda promover o causar discriminación, discriminación , daño, calumnia, odio o violencia contra cualquier individuo o grupo; (iii) pone en peligro a niños y menores de edad; (iv) es o permite que se realicen actividades ilegales o fraudulentas; (v) es o puede constituir un delito penal o capital o de otro modo infringe cualquier ley aplicable; (vi) es o puede considerarse desnudez, erótico, obsceno, amenazante / difamatorio, plagiado, armas de fuego, tabaco, alcohol, marihuana, juegos de azar, material pornográfico o análogo; (vii) proporcionar apoyo o recursos informativos , ocultar y / o disfrazar el carácter, la ubicación o la fuente a cualquier organización delegada por el gobierno de los Estados Unidos como una "organización terrorista extranjera" de acuerdo con la Sección 219 de la Ley de Nacionalidad de Inmigración.
          <br />
          d- Recopilar, recibir, transferir o difundir cualquier información de identificación personal de cualquier persona sin el debido consentimiento del titular.
          <br />
          e- Solicitar dinero o cualquier producto digital o físico de otros usuarios.
          <br />
          f- Pida a otros usuarios que oculten la identidad, la fuente o el destino de cualquier dinero o producto obtenido ilegalmente.
          <br />
          g- Cargar información de compra infractora, falsa, duplicada, alterada o de cualquier otra forma engañosa.
          <br />
          h- Utilice cualquier proceso automatizado o manual para obtener, copiar, procesar , acceder y / o utilizar nuestros Servicios o cualquier parte de los mismos para capturar datos o contenido no autorizado, para cualquier propósito.
          <br />
          i- Habilite, emprenda, programe o ejecute cualquier tipo de sistema, programa informático o técnica para extraer datos, recuperar, raspar, indexar o extraer información no autorizada de la aplicación o el sitio o cualquier parte o suministro de datos de los mismos. Para fines de aclaración, tales acciones incluirán el uso de personas, aplicaciones de búsqueda / recuperación de sitios, 'robots' y 'arañas' de software y cualquier herramienta análoga de recopilación y extracción de datos, independientemente del tipo y la cantidad de información destinada a la extracción .
          <br />
          j- Utilice la aplicación o el sitio para difundir cualquier tipo de virus informáticos, gusanos, defectos, caballos de Troya u otros elementos de naturaleza destructiva.
          <br />
          k- Llevar a cabo cualquier acción que pueda o pueda causar una sobrecarga irrazonable en la Aplicación o el Sitio y / o la tecnología o infraestructura de sus socios y afiliados , o de lo contrario, exigir excesivamente el tráfico de la Aplicación o el Sitio.
          <br />
          l- Interceptar o monitorear la actividad a través de nuestros Servicios sin nuestra autorización expresa.
          <br />
          m- Acceda a la aplicación o al sitio con la intención y / o para crear una aplicación o sitio web similar o similar , o copie características, gráficos, ideas, imágenes, videos, propiedad intelectual o funciones de la aplicación o el sitio web;
          <br />
          n- De lo contrario, realice ingeniería inversa, descompile o extraiga el código de propiedad de la Aplicación o el Sitio .
          <br />
          <br />
          También reconoce y acepta que cualquier violación de las disposiciones mencionadas anteriormente puede resultar en la terminación inmediata de su acceso a Quartrly y nuestros Servicios, sin reembolso ni reembolso de nuestra parte. Podemos cancelar o suspender sucuenta y / o membresía para nuestro Servicio, en cualquier momento, por cualquier motivo, sin previo aviso, ya nuestra única y última discreción.
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Términos de la App Store
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Por la presente, reconoce y acepta que estos Términos se ejecutan entre Quartrly, Inc. y usted, y no entre Google, Inc. o Apple, Inc. (en adelante, "Apple" y "Google"). En adelante, la licencia otorgada a usted para la Aplicación también se limita a.
          <br />
          <br />
          una licencia no transferible para usar la Aplicación por un producto activado por un dispositivo de terceros autorizado que usted posee o controla, y según lo permitido por los términos de uso establecidos en el aplicable tienda de aplicaciones.
          <br />
          <br />
          Quartrly , y no cualquier proveedor externo de la tienda de aplicaciones, será el único responsable de la Aplicación y de cualquier contenido de la misma. Somos los únicos responsables de la aplicación, los servicios y el contenido de los mismos.
          <br />
          <br />
          En la medida en que estos Términos estipulen términos de uso o licencias para la Aplicación que sean menos restrictivas que los términos de uso establecidos en la tienda de aplicaciones aplicables, o que de lo contrario entren en conflicto con su uso respectivo, el término más restrictivo prevalecerá. .
          <br />
          <br />
          Somos los únicos responsables de proporcionar cualquier servicio de mantenimiento y soporte con respecto a la Aplicación, y ni Apple ni Google tienen la obligación de proporcionar ningún servicio de mantenimiento y soporte de los mismos.
          <br />
          <br />
          En caso de que la aplicación no cumpla con cualquier garantía aplicable, puede notificar a Apple o Google, y pueden reembolsarle el precio de compra de la aplicación únicamente (si corresponde); un nd, en la medida máxima permitida por la ley aplicable, ni Apple ni Google tendrá otras obligaciones de garantía alguna con respecto a la aplicación, y cualesquiera otras reclamaciones, pérdidas, responsabilidades, daños, costos o gastos atribuibles a cualquier falta de co Nform a cualquier garantía será responsabilidad de Quartrly.
          <br />
          <br />
          Por la presente, usted reconoce y acepta que Apple y Google (y sus afiliados) son terceros beneficiarios de estos Términos, y que, una vez que acepte los términos y condiciones de estos Términos, Apple y Google tendrán el derecho (y se considerarán haber aceptado el derecho) de hacer cumplir estos Términos en su contra como terceros beneficiarios de los mismos.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Propiedad
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Las marcas comerciales, los derechos de autor, las marcas de servicio, los nombres comerciales y otros derechos de propiedad intelectual y avisos de propiedad que se muestran en Quartrly son propiedad de –o de lo contrario están autorizados para– Quartrly o sus licenciantes o afiliados, ya sean reconocidos (o no), y que son protegidos bajo derechos de propiedad intelectual e intelectual en los Estados Unidos de América y otras jurisdicciones en todo el mundo. Los titulares respectivos pueden o no estar afiliados con nosotros o con nuestros afiliados, socios y anunciantes. Ninguna sección de este documento se interpretará como una intención de otorgarle ningún derecho o interés en Quartrly o nuestros Servicios, en todo o en parte.
          <br />
          <br />
          Para facilitar la comprensión, 'derechos de propiedad intelectual' significa todos y cada uno de los derechos de patente, derechos de autor, derechos de trabajo enmascarados, derechos morales, derechos de publicidad, marca registrada, imagen comercial y derechos de marca de servicio , buena voluntad, derechos de secreto comercial y otros derechos intelectuales. derechos de propiedad como pueden existir ahora o más adelante, y todas las solicitudes, registros y renovaciones y extensiones de los mismos, de conformidad con las leyes de los Estados Unidos de América y otras jurisdicciones aplicables. ;
          <br />
          <br />
          Usted reconoce y acepta que cualquier uso o explotación infractora de contenido protegido por derechos de autor en Quartrly y / o los Servicios puede causarnos a nosotros, a nuestros afiliados, licenciantes o proveedores de contenido daños irreparables, que no pueden remediarse únicamente por ley y, por lo tanto, a nuestros afiliados, Los otorgantes de licencias o proveedores de contenido pueden buscar remedio por incumplimiento de estos Términos, ya sea en equidad o a través de medidas cautelares u otra reparación equitativa..
          <br />
          <br />
          Por la presente, usted reconoce y acepta que, incluso en caso de que un tercero reclame que la Aplicación infringe los derechos de propiedad intelectual de un tercero, Quartrly, y no Google Inc. o Apple Inc., serán los únicos responsables de la investigación, defensa, acuerdo y el descargo de cualquier reclamo de infracción de propiedad intelectual ..
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Término, terminación
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          El término de este documento comenzará en la fecha que aparece primero entre: (i) primer acceso al Sitio; (ii) su primer acceso o ejecución de nuestra aplicación; o (iii) Quartrly pido ins que ofrecen sus servicios a usted.
          <br />
          <br />
          El término del presente finalizará automáticamente en la fecha anterior de su: (i) desactivación, suspensión, congelación o eliminación de la cuenta de la Aplicación; (ii) su terminación de acceso o revocación de acceso; (iii) la rescisión trimestral de estos Términos, a su exclusivo y último criterio ; (iv) la fecha de finalización que le indica Trimestralmente de vez en cuando; o (v) nuestra decisión de hacer que el Sitio o la Aplicación ya no estén disponibles para su uso, a su exclusivo y último criterio.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Licencias
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Desde el momento de la aceptación de estos Términos, Quartrly, Inc. le otorga una licencia individual, no sublicenciable, no transferible, revocable, no perpetua, limitada, no exclusiva y sujeta a las siguientes limitaciones para descargar, acceder y usar la Aplicación para su Propósitos individuales. Usted acepta no usar la aplicación para ningún otro propósito. A menos que Quartrly, Inc. especifique lo contrario, no está permitido reproducir, copiar, modificar, distribuir, transmitir, mostrar, publicar, licenciar, crear trabajos derivados de la aplicación o de cualquier parte de este. Quartrly, Inc. se reserva todos los derechos que no se otorgan expresamente en estas Condiciones de uso.
          <br />
          <br />
          Si descargó Quartrly de Apple App Store, por la presente afirma y acepta que solo usará Quartrly (i) en un producto de la marca Apple que ejecute iOS (sistema operativo propietario de Apple) y (ii) según lo permitido por el "Uso Reglas "establecidas en los Términos de servicio de Apple App Store.
          <br />
          <br />
          Licencia de contenido generado por el usuario . Por la presente, otorga a Quartrly un derecho ilimitado, no exclusivo, sin royalties, para todo el mundo, y licencia para descargar, usar, reproducir, distribuir, analizar y explotar cualquier y todo el contenido, textos, datos y cualquier componente del mismo que usted o sus afiliados, agentes, empleados o representantes introducen, cargan o entregan a Quartrl y . Usted declara y garantiza a Quartrly que tiene todos los derechos, autorizaciones o, de lo contrario, posee un título suficiente para todo el contenido enviado a Quartrly como se establece en este documento.
          <br />
          <br />
          Tal como se utiliza aquí o dentro de la Aplicación, 'Código Abierto' significa software de código abierto provisto dentro de la Aplicación que tiene licencia o sublicencia bajo los términos y condiciones de los acuerdos de licencia aplicables o avisos de derechos de autor incluidos dentro de dicho componente de Código Abierto . Estos Términos no se aplican ni rigen a ningún Código Abierto que acompañe a la Aplicación y, por la presente, Quartrly renuncia a todas y cada una de las garantías, representaciones y responsabilidades ante usted o cualquier tercero relacionado con ellas.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Enlaces de terceros
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          De vez en cuando, el Sitio, la Aplicación y / o los Servicios pueden contener hipervínculos a otros sitios web . Estos enlaces son para su conveniencia personal y para proporcionarle más información que pueda ser de su interés. La provisión de dichos enlaces no implica ningún respaldo de dichos sitios web de terceros (o sus productos y servicios), propiedad de terceros. Para evitar dudas, estos Términos no regirán en ningún caso el uso de sitios web de terceros, a los que se accede a través de enlaces de terceros.
          <br />
          <br />
          De vez en cuando, podemos colocar anuncios y promociones de fuentes de terceros en el Sitio, la Aplicación y / o los Servicios. En consecuencia, su participación o compromisos en promociones de terceros que no sean trimestrales, y cualquier término, condición, garantía o representación asociada con dichos compromisos, es únicamente entre usted y dicho tercero. Quartrly no se hace responsable de ninguna pérdida o daño de ningún tipo incurrido como resultado de dichos tratos o como resultado de la presencia de terceros anunciantes.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Privacidad del usuario
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Al divulgarnos cualquier información, usted acepta los términos de nuestra Política , incluidos los términos para la recopilación, el proceso, el almacenamiento y la divulgación de dicha información de identificación personal, incluidos nuestros afiliados, socios y clientes. Vamos a pedir su consentimiento expreso, incluyendo para su inclusión en nuestros boletines de noticias, u puede actualizar el clip, y seguir - ups. Para más información, lea nuestra Política.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Aviso de la Ley de Derechos de Autor del Milenio Digital ('DMCA')
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Para avisos de eliminación de DMCA y supuestas infracciones de los derechos de propiedad intelectual dentro de Quartrly, puede ponerse en contacto con nosotros como se indica en nuestra sección de contacto. Según la ley federal de los Estados Unidos, si a sabiendas tergiversa que el material en línea infringe, puede estar sujeto a enjuiciamiento penal por perjurio y sanciones civiles, incluidos daños monetarios, costos judiciales y honorarios de abogados.
          <br /><br />
          Debe notificarnos su reclamo con el tema: "Solicitud de eliminación". Una vez recibido, Q uartrly estudiará y considerará su reclamo y, si cree o tiene motivos para creer que cualquier contenido de Quartrly infringe los derechos de autor de otra persona, Quartrly puede eliminarlo, desactivarlo o dejar de mostrarlo. Tenga en cuenta que este procedimiento es exclusivo para notificar a Quartrly y sus filiales que su material protegido por derechos de autor ha sido infringido.
          <br /><br />
          Su reclamo de notificación de DMCA debe ser por escrito y debe contener al menos la siguiente información: (i) su firma e identificación, o las personas autorizadas para actuar en su nombre o el del titular; (ii) una descripción clara y concisa del contenido que usted alega es una infracción de derechos de autor; (iii) información de contacto (por ejemplo, dirección y correo electrónico); y (iv) una declaración, bajo pena de perjurio, que indique que usted cree de buena fe que la información provista en su reclamo es verdadera y precisa. Los requisitos anteriores están destinados a cumplir con los derechos y obligaciones trimestrales bajo la DMCA, incluyendo 17 USC 512 (c), pero no constituyen asesoramiento legal.
          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Sin garantía
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Ni Quartrly, Inc., ni sus filiales, subsidiarias, funcionarios, empleados y agentes garantizan que Quartrly esté libre de errores, sea ininterrumpido, seguro o produzca resultados particulares. Ningún consejo o información dada por Quartrly, Inc. o sus empleados, afiliados, contratistas y / o agentes creará una garantía. Quartrly no se ha probado por completo en todas las situaciones o dispositivos, y Quartrly puede contener o no funcionará correctamente, errores, virus, errores, gusanos , troyanos, bots y otros componentes o defectos dañinos y destructivos.
          <br /><br />
          Ni Quartrly, Inc. ni sus filiales, licenciantes, propietarios, subsidiarias, marcas o anunciantes son asesores profesionales en ninguna industria. Las funcionalidades y los resultados que se muestran en Quartrly pueden variar o variarán, sin previo aviso o sin previo aviso, y puede recibir Quartrs, o no, dependiendo de la disponibilidad de otros usuarios y / o funcionalidades de Quartrly, y cualquier resultado dependerá en una variedad de factores fuera del control final de Quartrly Su uso de cualquier información y / o materiales en Quartrly es bajo su propio riesgo, por lo cual no seremos responsables.
          <br /><br />
          En la mayor medida permitida por la ley aplicable, la aplicación y el sitio se le proporcionan "tal cual", con "todas las fallas" y "según esté disponible", con todas las fallas, sin garantía de ningún tipo, sin garantías de rendimiento ni garantías de ningún tipo amable, y su uso es bajo su propio riesgo. Ningún consejo oral o escrito proporcionado por Quartrly, Inc., sus afiliados, clientes, agentes, funcionarios, licenciantes, distribuidores y / o cualquier representante autorizado, creará ninguna garantía implícita. Todo el riesgo de calidad y rendimiento satisfactorios reside en usted. Quartrly, Inc. y sus afiliados, clientes, caballeros, funcionarios, otorgantes de licencias y / o distribuidores, no otorgan ni renuncian a ninguna de las garantías expresas, implícitas o legales, ya sea por ley, derecho consuetudinario, costumbre o uso de comercio, curso de negociación o de otro modo, sin importar el surgimiento, incluidas las garantías implícitas de descripción, calidad, idoneidad para un propósito particular, operación, integración, adecuación, idoneidad, título, no infracción, no interferencia con el uso y / o disfrute.
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Descargo de responsabilidad de daños
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          En ningún caso Quartrly, Inc., sus afiliados , clientes, agentes, funcionarios, licenciantes, distribuidores y / o un tercero autorizado, serán responsables de ningún daño especial, indirecto, incidental o consecuente, incluidas pérdidas, costos o gastos. de cualquier tipo resultante de la posesión, acceso, uso o mal funcionamiento de la Aplicación o Sitio, incluyendo, entre otros, pérdida de ingresos, ganancias, negocios, pérdida de uso o falta de disponibilidad de recursos informáticos, datos perdidos o corruptos, recuperación monto de adquisición, ahorros anticipados, gastos desperdiciados u otras pérdidas comerciales o económicas; o por cualquier daño indirecto, incidental, especial, punitivo, agravado, ejemplar o consecuente que surja o esté relacionado con la Aplicación o el Sitio, ya sea que surja en agravio (incluida negligencia), contrato, responsabilidad estricta u otra teoría legal o equitativa y si Quartrly, Inc., sus afiliados, clientes, licenciantes y / o distribuidores han sido informados de la posibilidad de tales daños. A los fines de esta limitación de responsabilidad, Quartrly, Inc. ' Los afiliados, licenciantes y distribuidores son terceros beneficiarios de las limitaciones de responsabilidad aquí especificadas y pueden hacer cumplir estos Términos en su contra.
          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Limitación de responsabilidad
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          En ningún caso, Quartrly ', Inc., o la responsabilidad de sus afiliados, clientes, licenciantes y / o distribuidores por todos los daños (excepto lo requerido por la ley aplicable) excederá la cantidad de USD $ 50.00 (Cincuenta dólares estadounidenses ); y de aquí en adelante, cualquier indemnización por daños directos y comprobables no excederá dicho monto total. Estos Términos le otorgan derechos legales específicos, y usted puede tener otros derechos que pueden variar de jurisdicción a jurisdicción. La legislación de algunos estados / países no permite ciertas limitaciones de responsabilidad, y en adelante esta limitación de responsabilidad se aplicará en la mayor medida permitida por la ley en la jurisdicción aplicable. Esta limitación de responsabilidad no será aplicable únicamente en la medida en que cualquier disposición específica de esta limitación de responsabilidad esté prohibida por cualquier ley federal, estatal o municipal, que no pueda ser anulada.
          <br /><br />
          Como nuestro usuario, usted reconoce y acepta que Google Inc. y Apple Inc., y sus filiales y subsidiarias, son terceros beneficiarios de estos Términos y que, al aceptar los términos y condiciones del mismo , tendrán el derecho (y se considerará que ha aceptado el derecho) de hacer cumplir estos Términos en su contra como un tercero beneficiario de los mismos.
          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Indemnización
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Deberá indemnizar, mantener inofensivo y defender a Quartrly , Inc. sus afiliados, clientes, agentes, oficiales, licenciantes, distribuidores y / o representantes autorizados, y los funcionarios, directores y empleados de cada uno (conjuntamente, los " Indemnité Quartrly ' " ) de y contra cualquier y todos los pasivos de terceros, reclamos, causas de acción, demandas, pérdidas, daños, multas, sentencias, acuerdos y gastos (incluidos todos y cada uno de los honorarios de abogados externos razonables y los costos judiciales) que pueden ser suffosados , hecho o incurrido por cualquiera de tales Quartrly 'Indemnizaciones derivadas de o relacionadas con: (i) cualquier incumplimiento de cualesquiera garantías, representaciones y / o convenios hechos por usted a continuación (en la medida en que no surjan sustancialmente de cualquier incumplimiento por parte de Quartrly ); y / o (ii) cualquier reclamación de un tercero que surja de o en relación con la Aplicación o el Sitio o el uso de la misma en combinación con su plataforma de negocios, incluyendo, sin limitación, cualquier reclamación de que la Aplicación o el Sitio infrinja, infrinja o se apropie indebidamente de cualquier propiedad o derechos de propiedad intelectual de cualquier tercero, incluidos, entre otros, cualquier derecho de privacidad de cualquier persona, o viola cualquier ley aplicable.
          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Generales
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Asignación . Estos términos redundarán en beneficio de cualquier sucesor de las partes.
          <br /><br />
          Moderación de contenidos . Quartrly, Inc. se reserva el derecho, a su discreción única y final, para revisar cualquier contenido entregado en aplicación o el sitio , y los asesores de uso y / o cualquier otra tecnología de monitoreo para la bandera y eliminar cualquier usuario - contenido generado u otro conte nt considerado inapropiado.
          <br /><br />
          Recursos equitativos : Usted reconoce y acepta que si estos Términos no se aplican específicamente, Quartrly, Inc. sufrirá daños irreparables y , por lo tanto, acepta que Quartrly, Inc. tendrá derecho, sin fianza, otra garantía o prueba de daños, a remedios equitativos apropiados con respecto a su incumplimiento de cualquiera de estos Términos, además de cualquier otro remedio disponible.
          <br /><br />
          Fuerza mayor . Quartrly, Inc. no será responsable de ninguna falla en el cumplimiento de sus obligaciones como se establece en el presente documento, cuando dicha falla surja de cualquier causa más allá de la de Quartrly ' , Inc. Control razonable, que incluye, entre otros, electrónica, electricidad, mecánica o Internet. fracaso.
          <br /><br />
          No Embargo . Por la presente, declara y garantiza que: (i) no está ubicado en un país que está sujeto a un embargo del gobierno de los EE. UU., O que ha sido designado por el gobierno de los EE. UU. Como un país de "apoyo terrorista"; y (ii) no figura en ninguna lista del gobierno de EE. UU. de actividades o fiestas prohibidas o restringidas.
          <br /><br />
          Sin renuncia . El incumplimiento por Quartrly, Inc. de hacer cumplir cualquier derecho en virtud del presente documento no se interpretará como una renuncia a ningún derecho con respecto al objeto del presente documento.
          <br /><br />
          No hay relación . Usted y Quartrly, Inc. son contratistas independientes, y no se ha creado ni creado una relación de agencia , sociedad, empresa conjunta, empleado-empleador o franquiciador-franquiciado por estos Términos.
          <br /><br />
          Reclamaciones de productos . Usted reconoce y acepta que Quartrly, Inc., y no Google Inc. o Apple Inc., es responsable de abordar cualquier reclamo suyo o de un tercero relacionado con la Aplicación en su uso o posesión, incluidos, entre otros: (i) reclamaciones de responsabilidad del producto; (ii) cualquier servicio de mantenimiento y soporte; (iii) cualquier reclamo de que la aplicación no cumple con los requisitos legales o reglamentarios aplicables; y (iv) reclamaciones derivadas de la protección del consumidor o legislación similar.
          <br /><br />
          Divisibilidad. Si alguna disposición de estos Términos no se puede hacer cumplir, entonces dicha disposición se modificará para reflejar la intención de las partes . Todas las disposiciones restantes de estos Términos permanecerán en pleno vigor y efecto.
          <br /><br />
          Renuncia a Acciones de Clase , Alivio No Individualizado . Usted reconoce y acepta que las reclamaciones presentadas contra Quartrly, Inc. solo se realizarán de forma individual y no como un demandante o miembro de Class en cualquier posible acción futura representativa o de clase o procedimiento similar. A menos que usted y Quartrly, Inc. acuerden lo contrario , no podrá adherirse ni consolidar ninguna reclamación con más de una persona; y, de lo contrario, no puede supervisar ni asumir ninguna forma de una clase, representante o proceso consolidado.
          <br /><br />
          Ley aplicable . Su uso de esta aplicación y sitio y cualquier causa de acción, reclamo y / o disputa que pueda surgir entre las partes a continuación, estará sujeto a las leyes del Estado de Nevada, Estados Unidos de América, sin tener en cuenta los principios de conflicto de
          <br /><br />
          Foro . Usted acepta que cualquier disputa que surja o esté relacionada con estos Términos será escuchada únicamente por un tribunal o tribunal de jurisdicción competente en o cerca de la Ciudad de Las Vegas, Estado de Nevada, Estados Unidos de América. Si presenta una disputa de otra manera que no sea de acuerdo con esta sección, acepta que podemos movernos para que se desestime y que será responsable de nuestros honorarios razonables de abogados, costos judiciales y desembolsos al hacerlo. Usted acepta que la parte que no haya tenido éxito en cualquier disputa que surja de o esté relacionada con estos Términos será responsable del reembolso de los honorarios razonables de abogados de la parte exitosa, los costos de los gastos y los desembolsos.
          <br /><br />
          Renuncia al juicio por jurado . Renuncia al juicio por jurado . Las partes en este documento renuncian a sus derechos constitucionales y legales para ir a la corte y tener un juicio ante un jurado, en su lugar, eligen que todas las reclamaciones y disputas sean resueltas por un juez competente.
          <br /><br />
        </Typography>
      </div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
          Contacto
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
          Si tiene alguna pregunta o consulta sobre nosotros, el Sitio, la Aplicación, nuestros Servicios o estos Términos, contáctenos como se indica en nuestra página de contacto: www.quartrly.com/contact. www.quartrly.com / contact.
          <br /><br />
          La fecha de la última actualización efectiva es el 31 de agosto de 2021.
          <br /><br />
        </Typography>
      </div>
    </div>
  )
}