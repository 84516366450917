import { ColorConfig } from "../colorConfig";

let { primaryColor } = ColorConfig;

export const MuiTypographyConfig = {
  // {/* variant = h1 ===> for blue primary headings */}
  h1: {
    fontFamily: "cerebri-bold",
    color: primaryColor,
    fontSize: "30px",
    // lineHeight:'40px'
  },
  //    {/* variant = body1 ===> for white color and basic primary captions */}
  body1: {
    fontFamily: "cerebri-regular",
    color: "#FFFFFF",
    fontSize: "16px",
  },
  //     {/* variant = body2 ===> for blue color and semi-bold strings strings */}
  body2: {
    fontFamily: "cerebri-semibold",
    color: primaryColor,
    fontSize: "18px",
  },
  //     {/* variant = subtitle1 ===> for white color and emphasize or regular small text strings */}
  subtitle1: {
    fontFamily: "cerebri-regular",
    color: "#ffffff",
    fontSize: "14px",
  },
  //    {/* variant = subtitle2 ===> for grey color and labels + bottom light weight strings */}
  subtitle2: {
    fontFamily: "cerebri-light",
    color: "#808080",
    fontSize: "14px",
  },
  //     {/* variant = caption ===> for grey color and  regular grey text strings */}
  caption: {
    fontFamily: "cerebri-regular",
    color: "#808080",
    fontSize: "16px",
  },
};
