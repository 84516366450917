import moment from "moment";

export default function monthlyRecord(userEarnings) {
    const startDate = moment(userEarnings[0]?.receipt_id?.createdAt).format('DD.MM.YYYY')
    let today = moment();
    let startingDate = moment(startDate, "DD.MM.YYYY");
    const startingMonth = moment(startingDate, 'DD.MM.YYYY').format('MMM YYYY')
    const monthsBetween = today.diff(startingDate, 'months');

    const allMonths = []
    for (let i = 0; i <= monthsBetween; i++) {
        const monthObj = {
            month: moment(startingMonth, 'MMM YYYY').add(i, 'month').format('MMM YYYY'),
            value: 0,
            referralFee: 0
        }
        allMonths.push(monthObj)
    }

    for (let i = 0; i <= userEarnings.length; i++) {
        const receipt = userEarnings[i]?.receipt_id
        const index = allMonths.findIndex((month, j) => {
            return moment(receipt?.createdAt).format("MMM YYYY") === month.month
        })
        if (index !== -1) {
            const prevValue = parseFloat(allMonths[index]?.value || 0)
            const receiptValue = parseFloat(userEarnings[i]?.receipt_id?.hostRate || 0) - parseFloat(userEarnings[i]?.receipt_id?.refundedAmount || 0)
            const newValue = prevValue + receiptValue
            allMonths[index].value = newValue

            const prevRef = parseFloat(allMonths[index]?.referralFee || 0)
            const receiptRef = parseFloat(userEarnings[i]?.receipt_id?.referralFee || 0)
            const newRef = prevRef + receiptRef
            allMonths[index].referralFee = newRef
        }
    }

    return allMonths.reverse()
}
