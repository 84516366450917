import moment from "moment";

export default function weeksOfMonths(userEarnings) {
    const startDate = moment(userEarnings[0]?.receipt_id?.createdAt).format('DD.MM.YYYY')
    let today = moment();
    let startingDate = moment(startDate, "DD.MM.YYYY");
    startingDate = moment(startingDate.day(startingDate.day() >= 4 ? 4 : -3).format("DD.MM.YYYY"), "DD.MM.YYYY");
    const startingMonth = moment(startingDate, 'DD.MM.YYYY').format('MMM YYYY')
    const weeksBetween = today.diff(startingDate, 'weeks');
    const monthsBetween = today.diff(startingDate, 'months');

    const allMonths = []
    for (let i = 0; i <= monthsBetween; i++) {
        const monthObj = {
            month: moment(startingMonth, 'MMM YYYY').add(i, 'month').format('MMM YYYY'),
            weeks: []
        }
        allMonths.push(monthObj)
    }

    let daysToAdd = startingDate.day() >= 4 ? 4 : -3; 
    let startingThr = startingDate.day(daysToAdd).format("DD.MM.YYYY")
    for (let i = 0; i <= weeksBetween; i++) {
        const weekObj = {
            thu: moment(startingThr, "DD.MM.YYYY").add(0, 'days').format("DD.MM.YYYY"),
            wed: moment(startingThr, "DD.MM.YYYY").add(6, 'days').add(86399, 'seconds').format("DD.MM.YYYY HHmmss"),
            value: 0,
            referralFee: 0
        }
        let tempDate = moment(startingThr, "DD.MM.YYYY")
        startingThr = tempDate.add(7, 'days').format("DD.MM.YYYY")

        const condWeek = moment(weekObj.thu, 'DD.MM.YYYY').format('MMM YYYY')
        const index = allMonths.findIndex(ind => ind.month === condWeek)
        if (index !== -1) {
            const abc = allMonths[index]?.weeks.push(weekObj)
        }
    }

    for (let i = 0; i <= userEarnings.length; i++) {
        const receipt = userEarnings[i]?.receipt_id

        const mapping = allMonths.map((month, j) => {
            const index = month?.weeks.findIndex(ind => {
                return moment(receipt?.createdAt).isBetween(moment(ind.thu, "DD.MM.YYYY"), moment(ind.wed, "DD.MM.YYYY HHmmss"));
            })
            if (index !== -1) {
                const prevValue = parseFloat(allMonths[j]?.weeks[index]?.value || 0)
                const receiptValue = parseFloat(receipt?.hostRate || 0) - parseFloat(receipt?.refundedAmount || 0)
                const newValue = prevValue + receiptValue
                allMonths[j].weeks[index].value = newValue

                const prevRef = parseFloat(allMonths[j]?.weeks[index]?.referralFee || 0)
                const receiptRef = parseFloat(receipt?.referralFee || 0)
                const newRef = prevRef + receiptRef
                allMonths[j].weeks[index].referralFee = newRef
            }
        })
    }

    for (let i = 0; i <= monthsBetween; i++) {
        const xyz = allMonths[i]?.weeks.reverse()
    }
    return allMonths.reverse()
}
