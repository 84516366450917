import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Typography, makeStyles, withStyles, Slide } from '@material-ui/core'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import Header from '@base/header';
import { InputField, SelectField } from '@base'
import * as BaseActions from 'store/actions'
import { pasteAuthHeaders } from '@utils'
import { isIOS, isAndroid } from 'react-device-detect';

const PopupBtn = withStyles({
  root: {
    // height: 42,
    '& .MuiButton-label': {
      fontSize: 18,
    },
  },
})(Button)
const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '91vh',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
  },
  confirmWrapper: {
    backgroundColor: '#111111',
    padding: '2rem',
    position: 'absolute',
    paddingBottom: "60px",
    zIndex: 1000,
    bottom: 0,
    left: 0,
    width: '100%',
  },
  cancelHeading: {
    color: '#31A7DB',
    fontWeight: 400,
    marginBottom: '1rem',
    fontSize: 18,
    fontFamily: 'Cerebri-SemiBold'
  },
  cancelConfirmText: {
    color: '#808080',
    marginBottom: '2rem',
    fontWeight: '400',
    fontSize: '16px'
  },
}))

export default function AddBankAccount() {

  const [accountError, setAccountError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const username = useSelector(({ BaseReducer }) => BaseReducer.UserReducer.full_name)
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles();
  return (
    <>
      <span style={{ cursor: 'pointer' }}><Header heading='Add Account' backArrow /></span>
      <Box style={{ padding: '0 1.5rem' }}>
        <Formik
          initialValues={{
            account_holder_name: username,
            account_type: '',
            RDFI_Routing_Number: '',
            RDFI_Confirm_Routing_Number: '',
            RDFI_Account_Number: '',
            confirmation: false
          }}
          onSubmit={(values, { setSubmitting }) => {
            axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}ach/add-ach-details`, values, pasteAuthHeaders())
              .then(dispatch(BaseActions.showLoading(true)))
              .then(({ data: { status, message, data } }) => {
                if (status?.toLowerCase() === 'fail') {
                  dispatch(BaseActions.hideLoading())
                  setErrorMessage(message)
                  setAccountError(true)
                  throw new Error(message)
                }
                dispatch(BaseActions.hideLoading())
                dispatch(BaseActions.setUserBankingData(data))
                history.push('/balance', 'account-added')
              })
              .catch((err) => {
                dispatch(BaseActions.hideLoading())
                // dispatch(BaseActions.showAlert({
                //   variant: 'error',
                //   message: err?.message ?? 'Failed to add bank account',
                // }))
                setSubmitting(false)
              })
          }}
          validationSchema={Yup.object().shape({
            //Account Holder Name
            account_holder_name: Yup
              .string()
              .trim()
              .test('includes-username', '*Should include username', (value) => {
                const [firstName, lastName] = username.split(' ')
                if (
                  value === username ||
                  (!lastName && (value.startsWith(firstName) || value.includes(` ${firstName} `))) ||
                  (
                    (value.startsWith(firstName) || value.includes(` ${firstName} `)) &&
                    (value.includes(` ${lastName} `) || value.endsWith(lastName))
                  )
                ) {
                  return true
                }
                return false
              }),
            // .required('*Required'),
            //Account Type
            account_type: Yup
              .string()
              .trim(),
            // .required('*Required'),
            //Routing Number
            RDFI_Routing_Number: Yup
              .number()
              .test('RDFI_Routing_Number_length', '*Should be exactly 9 digits', value => {
                const routeNum = value?.toString?.().length
                if (routeNum === 0) return true
                if (routeNum < 9 || routeNum > 9) return false
                return true
              })
              .typeError('*The routing number you entered is invalid'),
            // .required('*Required'),
            //Confirm Routing Number
            RDFI_Confirm_Routing_Number: Yup
              .number()
              .when("RDFI_Routing_Number", {
                is: val => (val ? true : false),
                then: Yup.number().oneOf(
                  [Yup.ref("RDFI_Routing_Number")],
                  "*Routing numbers did not match"
                )
              })
              .test('RDFI_Routing_Number_length', '*Should be exactly 9 digits', value => {
                const routeNum = value?.toString?.().length
                if (routeNum === 0) return true
                if (routeNum < 9 || routeNum > 9) return false
                return true
              })
              .typeError('*The routing number you entered is invalid'),
            //Account Number
            RDFI_Account_Number: Yup
              .number()
              // .required('*Required')
              .typeError('*Should be number').test('RDFI_Routing_Number_length', '*Should be 4-17 digits', value => {
                const accountNoLength = value?.toString?.().length
                if (accountNoLength === 0) return true
                if (accountNoLength < 4 || accountNoLength > 17) return false
                return true
              }),
            confirmation: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')
          })}
        >
          {({
            values,
            errors,
            isValid,
            isSubmitting,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                <InputField
                  name='account_holder_name'
                  label='Account holder name'
                  value={values.account_holder_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.account_holder_name}
                  disabled={true}
                  color="grey"
                />
                <Box style={{ marginTop: 10 }}>
                  <Typography variant='subtitle2'>Account type</Typography>
                  <SelectField
                    name='account_type'
                    value={values.account_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.account_type}
                    options={[
                      { label: 'Checking Account', value: 'checking' },
                      { label: 'Savings Account ', value: 'savings' },
                    ]}
                  />
                </Box>
                <InputField
                  label='Routing number'
                  name='RDFI_Routing_Number'
                  value={values.RDFI_Routing_Number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.RDFI_Routing_Number}
                  type="number"
                  pattern="[0-9]+"
                  inputmode="numeric"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
                <InputField
                  label='Confirm Routing number'
                  name='RDFI_Confirm_Routing_Number'
                  value={values.RDFI_Confirm_Routing_Number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.RDFI_Confirm_Routing_Number}
                  type="number"
                  pattern="[0-9]+"
                  inputmode="numeric"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
                <InputField
                  label='Account number'
                  name='RDFI_Account_Number'
                  value={values.RDFI_Account_Number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.RDFI_Account_Number}
                  type="number"
                  pattern="[0-9]+"
                  inputmode="numeric"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
                <label>
                  <Field
                    type="checkbox"
                    checked={values.confirmation}
                    error={errors.confirmation}
                    className={'form-check-input ' + (errors.confirmation ? ' is-invalid' : '')}
                    name="confirmation" />
                  <Typography variant="caption">I confirm the above bank account belongs to me and details are accurate</Typography>
                </label>
                <Button type='submit' disabled={!dirty || isSubmitting || !isValid || values.account_type.length === 0} fullWidth>Add</Button>
              </form>
            )
          }}
        </Formik>
        <Slide direction='up' in={accountError}>
          <div className={classes.backdrop} style={{ display: accountError ? 'inherit' : 'none' }}>
            <div className={classes.confirmWrapper}>
              <Typography variant='subtitle1' className={classes.cancelHeading}>Invalid Bank Account</Typography>
              <Typography variant='subtitle2' className={classes.cancelConfirmText}>
                {errorMessage && errorMessage}
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: isIOS || isAndroid ? "120px" : "40px" }}>
                <PopupBtn
                  onClick={() => setAccountError(false)}
                  fullWidth
                >
                  Ok
                </PopupBtn>
              </div>
            </div>
          </div>
        </Slide>
      </Box>
    </>
  )
}
